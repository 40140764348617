import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
const pinia = createPinia()

//theme colors
const lightTheme = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    'surface-bright': '#FFFFFF',
    'surface-light': '#EEEEEE',
    'surface-variant': '#424242',
    'on-surface-variant': '#EEEEEE',
    primary: '#5647FF',
    'primary-darken-1': '#1F5592',
    secondary: '#424242',
    'secondary-darken-1': '#018786',
    error: '#FFA1A1',
    info: '#B3E5FC',
    success: '#8EF48B',
    warning: '#FFF27E',
  },
  variables: {
    'activated-opacity': 0,
    'hover-opacity': 0,
    'track-opacity': 0,
    'track-opacity': 0,
    'focus-opacity': 0,
  }
}

//defaults: 
const defaults = {
  VBtn: {
    class: 'text-capitalize',
    variant: 'flat',
    rounded: 'lg'
  },
  VTextField: {
    flat: true,
  },
  VTextarea: {
    flat: true,
    variant: 'solo-filled',
    rounded: 'lg'
  }
}

// Vuetify
import 'vuetify/styles'
import '@/styles/settings.scss'
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
const vuetify = createVuetify({
    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
        mdi,
      },
    },
    defaults: defaults,
    theme: {
      defaultTheme: 'lightTheme',
      themes: {
        lightTheme,
      },
    },
  })

//i18n
import i18n from './i18n/i18n.js'

import './registerServiceWorker'

const app = createApp(App).use(router).use(pinia).use(i18n).use(vuetify)

app.mount('#app')
