<template>
    <v-menu location="end">
      <template v-slot:activator="{ props }">
        <v-list-item class="font-weight-medium" variant="text" :ripple="false" color="primary" v-bind="props" :append-icon="mdiMenuRight">
            <v-icon class="mr-2" :icon="mdiWeb"></v-icon> <span>{{ locales[i18n.locale.value] }}</span>
        </v-list-item>
      </template>
      <v-list elevation="1">
        <v-list-item
          v-for="(languageName, locale) in locales"
          :key="`locale-${locale}`"
          :value="locale"
          :ripple="false"
          @click="handleSwitchLocale(locale)"
          class="px-10"
        >
          <v-list-item-title>{{ languageName }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
</template>

<script>
import { useI18n } from "vue-i18n"
import { mdiWeb, mdiMenuRight } from '@mdi/js'
export default {
    setup() {

        const locales = {
          "en": "English",
          "es": "Español",
          "fr": "Français",
          "pt": "Português",
          "de": "Deutsch",
          "hi": "हिंदी",
        }

        const i18n = useI18n()
        const handleSwitchLocale = (locale) => {
            i18n.locale.value = locale
            localStorage.setItem('app_locale', i18n.locale.value)
        }

        return { handleSwitchLocale, i18n, mdiWeb, mdiMenuRight, locales }
    }
}
</script>

<style scoped>
.text-left {
  justify-content: left;
}
</style>