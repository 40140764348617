<template>
    <v-dialog activator="parent" max-width="800" persistent>
        <template v-slot:default="{ isActive }">
            <v-card
                rounded="lg"
                flat
            >
                <p class="mt-2 mr-2 text-right">
                    <v-btn size="x-small" variant="tonal" :icon="mdiClose" @click="isActive.value = false"></v-btn>
                </p>
                <div class="pb-8 pt-4 px-8">
                    <p class="text-center exie-text-h5">{{ modalTitle }}</p>
                    <v-row class="mt-5">
                        <v-col cols="12" md="6" width="100%" class="pa-3">
                            <v-sheet class="rounded-xl py-5 px-8 bg-grey-lighten-4">
                                <p class="exie-text-subtitle-1 font-weight-bold">
                                    <span>{{$t('accountSettings.billing.plans.light.title')}}</span>
                                </p>
                                <p class="mt-3 exie-text-body-2">
                                    <span>{{$t('accountSettings.billing.plans.light.description')}}</span>
                                </p>
                                <p class="mb-3 mt-3 exie-text-body-2 font-weight-medium">{{$t('accountSettings.billing.plans.light.includedTitle')}}</p>
                                <p class="text-pre-line exie-text-body-2">
                                    {{$t('accountSettings.billing.plans.light.includedDescription')}}
                                </p>
                                <p class="mb-3 mt-3 exie-text-body-2 font-weight-medium">{{$t('accountSettings.billing.plans.light.availableAddonsTitle')}}</p>
                                <p class="text-pre-line exie-text-body-2">
                                    {{$t('accountSettings.billing.plans.light.availableAddonsDescription')}}
                                </p>
                                <p class="exie-text-h5 mt-8">
                                    <span>{{$t('accountSettings.billing.upgradeToProModal.todayTotal')}}</span>
                                    <span class="float-right">$20</span>
                                </p>
                                <p class="mt-5">
                                    <v-btn color="primary" block size="large" :disabled="loading" @click="handleUpgrateToLight">
                                        {{$t('accountSettings.billing.upgradeToProModal.btnUpgradeToLight')}}
                                    </v-btn>
                                </p>
                            </v-sheet>
                        </v-col>
                        <v-col cols="12" md="6" width="100%" class="pa-3">
                            <v-sheet class="rounded-xl py-5 px-6 bg-grey-lighten-4">
                                <p class="exie-text-subtitle-1 font-weight-bold">
                                    <span>{{$t('accountSettings.billing.plans.pro.title')}}</span>
                                </p>
                                <p class="mt-3 exie-text-body-2">
                                    <span>{{$t('accountSettings.billing.plans.pro.description')}}</span>
                                </p>
                                <p class="mb-3 mt-3 exie-text-body-2 font-weight-medium">{{$t('accountSettings.billing.plans.pro.includedTitle')}}</p>
                                <p class="text-pre-line exie-text-body-2">
                                    {{$t('accountSettings.billing.plans.pro.includedDescription')}}
                                </p>
                                <p class="mb-3 mt-3 exie-text-body-2 font-weight-medium">{{$t('accountSettings.billing.plans.pro.availableAddonsTitle')}}</p>
                                <p class="text-pre-line exie-text-body-2">
                                    {{$t('accountSettings.billing.plans.pro.availableAddonsDescription')}}
                                </p>
                                <p class="exie-text-h5 mt-8">
                                    <span>{{$t('accountSettings.billing.upgradeToProModal.todayTotal')}}</span>
                                    <span class="float-right">$50</span>
                                </p>
                                <p class="mt-5">
                                    <v-btn color="primary" block size="large" :disabled="loading" @click="handleUpgrateToPro">
                                        {{$t('accountSettings.billing.upgradeToProModal.btnUpgradeToPRO')}}
                                    </v-btn>
                                </p>
                            </v-sheet>
                        </v-col>
                    </v-row>
                    <p class="exie-text-caption text-center mt-3 text-medium-emphasis">
                        Powered by Stripe
                    </p>
                </div>
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
import { ref } from 'vue'
import { mdiClose} from '@mdi/js'
import api from '@/utils/api'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

export default {
    props: ['title'],
    setup(props) {
        const {t} = useI18n()
        const route = useRoute()
        const loading = ref(false)

        const modalTitle = ref(t('accountSettings.billing.upgradeToProModal.title'))
        if(props.title) {
            modalTitle.value = props.title
        }

        const handleUpgrateToLight = async () => {
            loading.value = true
            const response = await api.post(`/api/billing/${route.params.account_id}/subscription-checkout-session`, {
                plan: 'light'
            })
            if (response.error) {
                console.log('something went wrong')
                loading.value = false
                return
            }

            window.location.assign(response.url)
        }

        const handleUpgrateToPro = async () => {
            loading.value = true
            const response = await api.post(`/api/billing/${route.params.account_id}/subscription-checkout-session`, {
                plan: 'pro'
            })
            if (response.error) {
                console.log('something went wrong')
                loading.value = false
                return
            }

            window.location.assign(response.url)
        }

        return {
            mdiClose,
            handleUpgrateToLight,
            handleUpgrateToPro,
            loading,
            modalTitle
        }
    }
}
</script>