<template>

</template>
<script>
import { mapStores } from 'pinia'
import {useMixPanelStore} from '@/stores/MixPanelStore'
import {useAppStateStore} from '@/stores/AppStateStore'
import api from '@/utils/api'
export default {
    computed: {
        ...mapStores(useMixPanelStore, useAppStateStore)
    },
    methods: {
        async updateCurrentAccount(accountId) {
            const response = await api.put('/api/account/current', {
                account_id: accountId
            })
            if(!response.error) {
                this.appStateStoreStore.updateCurrentAccount(response)
                return true
            }
            return false
        }
    },
    watch: {
        '$route'(to, from) {
            if(to?.meta?.requireAuth == true && !this.appStateStoreStore.user) {
                this.$router.push({name: 'login'})
            }
            
            if(to.params.account_id && to.params.account_id !== from.params.account_id) {
                this.updateCurrentAccount(to.params.account_id).then(result => {
                    if(result == false) {
                        const routeParams = {...to.params, account_id: this.appStateStoreStore.currentAccountId}
                        this.$router.push({name: to.name, params: routeParams, hash: to.hash})
                    } 
                })
            }
        },
        'appStateStoreStore.currentAccountId'(newVal, oldVal) {
            if(this.$route.params.account_id && this.$route.params.account_id !== newVal) {
                const newParams = {...this.$route.params, account_id: newVal}
                this.$router.push({
                    name: this.$route.name,
                    params: newParams,
                    hash: this.$route.hash
                })
            }
        }
    },
    created() {
        this.mixPanelStoreStore.init()
    }
}
</script>