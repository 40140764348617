<template>
    <v-container>
        <v-breadcrumbs class="text-medium-emphasis">
            <v-breadcrumbs-item :to="{name: 'accounts'}"> 
                {{ $t('accounts.title') }}
            </v-breadcrumbs-item>
            <v-breadcrumbs-divider />
            <v-breadcrumbs-item title="Create New Account" :to="{name: 'account-create'}">
                {{ $t('accountCreate.title') }}
            </v-breadcrumbs-item>
        </v-breadcrumbs>

        <v-row>
            <v-col cols="12">
                <div class="w-100 d-flex justify-center pa-5 exie-text-body-1">
                    {{ $t('accountCreate.errorCreatingFromIg') }}
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    setup() {

        return {}
    }
}
</script>