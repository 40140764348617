<template>
    <v-container>
        <h4 class="exie-text-h4 mb-3">{{$t('helpCenter.title')}}</h4>

        <v-row>
            <v-col cols="12" sm="6" md="6" class="exie-text-body-1">
                <div class="mt-10">
                    {{ $t('helpCenter.description', {email: 'support@exie.app'}) }}
                </div>
                <div class="mt-6">
                    <i18n-t keypath="helpCenter.guide.text" tag="p">
                        <template v-slot:url>
                            <a class="exie-link" href="https://help.exie.app" target="_blank">{{ $t('helpCenter.guide.link') }}</a>
                        </template>
                    </i18n-t>
                </div>
                <div class="mt-6">
                    {{ $t('helpCenter.exieTeam') }} <span class="text-black">❤️</span>
                </div>
            </v-col>
        </v-row>
        
    </v-container>
</template>

<script>
</script>