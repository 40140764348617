<template>
    <div v-if="loaded">
        <v-container v-if="!appsumoCode" class="centered">
            <AppsumoInvalidCode />
        </v-container>
        <v-container v-else-if="appsumoCode.status == 0">
            <v-row>
                <v-col cols="12" md="6">
                    <p class="exie-text-h4">{{ $t('appsumo.title') }}</p>
                </v-col>
            </v-row>
            <v-row class="exie-text-body-1 mb-5">
                <v-col cols="12" md="6">
                    <p>{{ $t('appsumo.descriptionLine1') }}</p>
                    <p>{{ $t('appsumo.descriptionLine2') }}</p>
                    <p class="mt-2">
                        <i18n-t keypath="appsumo.descriptionCode" tag="p">
                            <template v-slot:code>
                                <span class="text-black">{{ appsumoCode.code }}</span>
                            </template>
                        </i18n-t>
                    </p>
                </v-col>
            </v-row>
            <v-sheet v-if="accounts.length" v-for="(account, index) in accounts" :key="index"
                class="border border-thin rounded-lg my-3"
            >
                <v-row class="ma-5">
                    <v-col cols="12" md="2" class="pa-0 d-flex align-center">
                        <v-badge :content="getProStatusLabel(account.pro_status)" :color="getProStatusLabelColor(account.pro_status)">
                            <v-avatar color="grey-darken-3" :image="account.picture" size="45" />
                        </v-badge>
                    </v-col>
                    <v-col cols="6" md="3" class="pa-0">
                        <p class="exie-text-body-1 mt-1">{{ account.name }}</p>
                        <p class="exie-text-caption text-medium-emphasis">{{ account.instagram_username }}</p>
                    </v-col>
                    <v-col cols="6" md="7" class="pa-0 d-flex align-center justify-end">
                        <v-btn v-if="account.pro_status == 0" flat color="primary" @click="handleActivateCode(account.id)">{{ $t('appsumo.btnRedeemTheCode') }}</v-btn>
                        <v-btn v-else readonly flat color="grey-lighten-2 text-grey-darken-1">
                            <v-tooltip
                                activator="parent"
                                location="top"
                            >
                                <div class="pa-1 text-center" :style="{maxWidth: '150px'}">
                                    {{ $t('appsumo.tooltipOnlyOnTrial') }}
                                </div>
                            </v-tooltip>
                            {{ $t('appsumo.btnRedeemTheCode') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-sheet>
            <v-sheet v-else class="rounded-lg bg-grey-lighten-4 py-10 d-flex flex-column align-center">
                <div class="exie-text-body-1" :style="{maxWidth: '380px'}">
                    {{ $t('appsumo.noAccountsDescription') }}
                </div>
                <v-btn color="primary" class="mt-5" :to="{name: 'account-create'}">{{ $t('appsumo.btnCreateAccount') }}</v-btn>
            </v-sheet>

        </v-container>
        <v-container v-else-if="appsumoCode.status == 1" class="centered">
            <AppsumoActivatedCode />
        </v-container>
        <v-container v-else class="centered">
            <AppsumoInvalidCode />
        </v-container>
    </div>
</template>

<script>
import {useRoute, useRouter} from 'vue-router'
import api from '@/utils/api'
import {ref} from 'vue'
import {getProStatusLabel, getProStatusLabelColor} from '@/utils/AccountUtils'
import AppsumoInvalidCode from '@/components/appsumo/AppsumoInvalidCode.vue'
import AppsumoActivatedCode from '@/components/appsumo/AppsumoActivatedCode.vue'
export default {
    components: {AppsumoInvalidCode, AppsumoActivatedCode},
    setup() {
        const route = useRoute()
        const router = useRouter()
        const code = route.params.code
        const loaded = ref(false)

        const appsumoCode = ref(null)

        const accounts = ref([])

        const getAppsumoCode = async () => {
            const response = await api.get(`/api/appsumo/code/${code}`)
            appsumoCode.value = response.appsumo_code
            loaded.value = true
            loadAccounts()
        }


        const loadAccounts = async () => {
            const response = await api.get(`/api/account/list`)
            accounts.value = response
            accounts.value.sort((a, b) => {
                return a.pro_status == 0 ? -1 : 1
            })
        }

        const handleActivateCode = async(accountId) => {
            const response = await api.post(`/api/appsumo/${accountId}/activate/${code}`)
            if(response.error) {
                console.log(response.error)
            }

            if(response.success) {
                router.push({name: 'dashboard', params: {account_id: accountId}, query: {appsumo: 'activated'}})
            }
        }

        getAppsumoCode()

        return {code, accounts, getProStatusLabel, getProStatusLabelColor, appsumoCode, loaded, handleActivateCode}
    }
}
</script>

<style scoped>
.centered {
    position: absolute;               /* 2 */
    left: 50%;                         /* 3 */
    transform: translate(-50%, 0)
}
</style>