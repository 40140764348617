import { defineStore } from "pinia"
import api from '@/utils/api'


export const useAccountDataStore = defineStore('accountDataStore', {
    state: () => ({
        accountId: null,
        featureFlags: null,
        marketingFlags: null
    }),
    getters: {
        hasFeatureFlag: (state) => {
            return (flag) => {
                return state.featureFlags && state.featureFlags[flag] == true
            }
        }
    },
    actions: {
        async init(accountId) {
            if(!this.accountId || this.accountId != accountId) {
                await this.reload(accountId)
            }
        },
        async reload(accountId) {
            const response = await api.get(`/api/account/${accountId}/account-data`)
            if(!response.error) {
                this.accountId = accountId
                this.featureFlags = response.account_data.feature_flags
                this.marketingFlags = response.account_data.marketing_flags
            }
        }
    }
})