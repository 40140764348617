<template>
    <div class="card">
        <div :style="{maxHeight: messagesContainerMaxHeight}" class="border border-thin rounded-lg d-flex flex-column overflow-y px-5 py-3" ref="messageContainer">
            <div v-for="message in messages" :key="message.id" :class="[message.type]" class="text-pre-line" @click="handleClickMessage(message)">
                {{ message.text }}
            </div>
        </div>
        <div class="mt-3">
            <v-row>
                <v-col cols="12" md="8" lg="9">
                    <v-text-field width="100" rounded="lg" hide-details flat variant="solo-filled" :placeholder="$t('playground.textYourMessage')" v-model="currentMessage" @keyup.enter="handleSendMessage"></v-text-field>
                </v-col>
                <v-col cols="12" md="4" lg="3">
                    <v-btn block size="x-large" color="primary" v-if="!sending" @click="handleSendMessage">{{$t('playground.btnSend')}}</v-btn>
                    <v-btn block size="x-large" disabled v-if="sending">{{$t('playground.btnSending')}}</v-btn>
                </v-col>
                
            </v-row>
            <p class="float-end mt-1">{{$t('playground.availableMessages')}} {{ messagesAvailable }}</p>
        </div>
    </div>
    <v-snackbar location="top right" color="error" v-model="error" :timeout="4000">
        {{ errorMessage }}
    </v-snackbar>
</template>

<script>
import {v4 as uuidv4} from 'uuid'
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useAppStateStore } from '@/stores/AppStateStore'
import { useAccountStatsStore } from '@/stores/AccountStatsStore'
import { useI18n } from "vue-i18n"
import api from '@/utils/api'
import { storeToRefs } from 'pinia'

export default {
    props: ['onboardingMode', 'account_id', 'messagesContainerMaxHeight'],
    setup(props) {
        const {t} = useI18n()
        const messages = ref([])
        const currentMessage = ref(null)
        const messagesContainerMaxHeight = props.messagesContainerMaxHeight ?? '50dvh;'
        const route = useRoute()
        const messageContainer = ref(null)
        const accountStatsStore = useAccountStatsStore()
        
        const { messagesAvailable } = storeToRefs(accountStatsStore)
        const sending = ref(false)
        const error = ref(false)
        const errorMessage = ref(null)

        const appStateStore = useAppStateStore()
        

        const accountId = props.account_id

        watch(messages.value, () => {
            setTimeout(() => {
                messageContainer.value.scrollTop = messageContainer.value.scrollHeight
            }, 20)
        })

        const getAvailableMessages = async () => {
            accountStatsStore.init(accountId)
        }

        getAvailableMessages()

        const handleClickMessage = (message) => {
            if (message.payload) {
                if (message.payload === 'call_operator' && appStateStore.currentAccount.call_operator_response) {
                    setTimeout(() => {
                        messages.value.push({
                            id: uuidv4(),
                            type: 'out',
                            text: appStateStore.currentAccount.call_operator_response
                        })

                        api.post(`/api/ai/notify/${accountId}`)
                    }, 300)
                }
            }
            
        }

        const handleSendMessage = async () => {     
            if (!currentMessage.value) {
                return
            }
            if (sending.value) {
                error.value = true
                errorMessage.value = t('playground.notify.messageProcessing')

                return
            }  
            if (messagesAvailable.value <= 0) {
                error.value = true
                errorMessage.value = t('playground.notify.noMessages')
                
                return
            }
            sending.value = true 
            messages.value.push({
                id: uuidv4(),
                type: 'out',
                text: currentMessage.value
            })

            messageContainer.value.scrollTop = messageContainer.value.scrollHeight

            const query = currentMessage.value
            currentMessage.value = null

            const responseData = await api.post(`/api/ai/query-from-index/${accountId}`, {
                query: query,
                onboarding: props.onboardingMode ? 1 : 0
            }).catch(() => {
                error.value = true
                errorMessage.value = t('playground.notify.unknownError')
                sending.value = false
            })

            if (responseData.error) {
                error.value = true
                errorMessage.value = responseData.error
                sending.value = false
                return
            }

            messages.value.push({
                id: uuidv4(),
                type: 'in',
                text: responseData.message.text
            })

            if(responseData.buttons && responseData.buttons.length > 0) {
                responseData.buttons.forEach((button) => {
                    messages.value.push({
                        id: uuidv4(),
                        type: 'in_button',
                        text: button.title,
                        payload: button.payload
                    })
                })
            }

            sending.value = false
        }

        return { messages, currentMessage, handleSendMessage, messageContainer, messagesAvailable, sending, error, errorMessage, handleClickMessage, messagesContainerMaxHeight }
    }
}
</script>

<style scoped>

.in {
    border-radius: 10px;
    background: #EEEEEE;
    align-self: flex-start;
    text-align: left;
    max-width: 40dvw;
    min-width: 30px;
    padding: 10px 20px;
    margin: 3px 10px;
}
.in_button {
    background: #EEEEEE;
    max-width: 30dvw;
    min-width: 30px;
    width: auto;
    padding: 5px 10px;
    margin: 0px 10px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    align-self: self-start;
    box-shadow: 2px 2px 2px #999999;
}
.out {
    border-radius: 10px;
    background: rgba(42, 42, 42);
    align-self: flex-end;
    color: white;
    text-align: right;
    max-width: 40dvw;
    min-width: 30px;
    padding: 10px 20px;
    margin: 10px;
}
.overflow-y {
    overflow-y: scroll;
    height: 50dvh;
}
</style>