<template>
    <v-container>
        <h4 class="exie-text-h4 mb-3">{{$t('playground.title')}}</h4>
        <v-row class="mt-5 mb-3 mx-0 px-0">
            <v-col cols="12" sm="6" md="6" class="exie-text-body-1 mx-0 px-0">
                {{$t('playground.description')}}
            </v-col>
        </v-row>
        <p class="mt-10 font-weight-bold">{{$t('playground.subtitle')}}</p>
        <v-sheet class="d-flex justify-center mt-5">
            <div class="w-100">
                <PlayChat :account_id="accountId" />
            </div>
        </v-sheet>
    </v-container>
</template>

<script>
import PlayChat from '@/components/playground/PlayChat.vue'
import { useDisplay } from 'vuetify'
import { useRoute } from 'vue-router'
import { ref } from 'vue'
export default {
    components: { PlayChat },
    setup() {
        const { xs } = useDisplay()
        const route = useRoute()
        const accountId = ref(route.params.account_id)

        return { xs, accountId }
    }
}
</script>
