<template>
    <v-dialog activator="parent" max-width="600" persistent>
        <template v-slot:default="{ isActive }">
            <v-card
                rounded="lg"
                flat
            >
                <p class="mt-2 mr-2 text-right">
                    <v-btn size="x-small" variant="tonal" :icon="mdiClose" @click="isActive.value = false"></v-btn>
                </p>
                <div class="pb-4 pt-4 px-8">
                    <div v-if="step == 0">
                        <p class="text-center exie-text-h5">{{$t('accountSettings.billing.cancelSubscriptionModal.title')}}</p>
                        <p class="text-center exie-text-body-1 text-pre-line mt-5 mb-8">
                            {{ pro_status == 1 ? $t('accountSettings.billing.cancelSubscriptionModal.descriptionPro') : $t('accountSettings.billing.cancelSubscriptionModal.descriptionLight')}}
                        </p>
                        <p class="mt-8">
                            <v-btn color="primary" variant="flat" class="float-left" @click="isActive.value = false">
                                {{ pro_status == 1 ? $t('accountSettings.billing.cancelSubscriptionModal.btnCancelPro') : $t('accountSettings.billing.cancelSubscriptionModal.btnCancelLight') }}
                            </v-btn>
                            <v-btn color="red" variant="text" class="float-right" @click="step += 1" :loading="loading">
                                {{$t('accountSettings.billing.cancelSubscriptionModal.btnConfirm')}}
                            </v-btn>
                        </p>
                    </div>
                    <div v-if="step == 1">
                        <div class="d-flex justify-center text-center w-100">
                            <div style="max-width: 370px;" class="exie-text-h5">{{$t('accountSettings.billing.cancelSubscriptionModal.step2Title')}}</div>
                        </div>
                        <p class="mt-8">
                            <v-radio-group v-model="selectedReason">
                                <v-radio v-for="(reason, index) in reasons" :key="index" :label="reason.title" :value="reason.value" />
                                <v-radio label="Other" value="other" />
                            </v-radio-group>

                            <v-textarea v-if="selectedReason == 'other'" rounded="lg" no-resize rows="3" label="Enter your reason here (optional)" flat variant="solo-filled" v-model="cancelComment"></v-textarea>
                        </p>
                        <p class="mt-8">
                            <v-btn color="primary" variant="flat" class="float-left" @click="isActive.value = false">
                                {{ pro_status == 1 ? $t('accountSettings.billing.cancelSubscriptionModal.btnCancelPro') : $t('accountSettings.billing.cancelSubscriptionModal.btnCancelLight') }}
                            </v-btn>
                            <v-btn :disabled="!selectedReason" color="red" variant="text" class="float-right" @click="handleCancelSubscription(isActive)" :loading="loading">
                                {{$t('accountSettings.billing.cancelSubscriptionModal.btnConfirm')}}
                            </v-btn>
                        </p>
                    </div>
                </div>
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
import { ref } from 'vue'
import { mdiClose } from '@mdi/js'
import api from '@/utils/api'
import { useRoute } from 'vue-router'
import { useAccountSubscriptionStore } from '@/stores/AccountSubscriptionStore'

export default {
    props: ['pro_status'],
    setup() {
        const loading = ref(false)
        const route = useRoute()
        const accountId = route.params.account_id

        const step = ref(0)

        const selectedReason = ref(null)
        const cancelComment = ref(null)

        const reasons = [
            {title: "The price is too high", value: "the_price_is_too_high"},
            {title: "Not enough value", value: "not_enough_value"},
            {title: "Didn't get how to use", value: "did_not_understand_product"},
        ]

        reasons.sort(() => Math.random() - 0.5)

        const accountSubscriptionStore = useAccountSubscriptionStore()

        const handleCancelSubscription = async (isActive) => {
            loading.value = true
            const response = await api.post(`/api/billing/${accountId}/cancel-subscription`, {
                reason: selectedReason.value,
                comment: cancelComment.value
            })
            loading.value = false
            if(response.error) {
                console.log(response.error)
                return
            }

            accountSubscriptionStore.update(accountId, response.subscription)
            isActive.value = false
        }

        return  {
            mdiClose,
            loading,
            handleCancelSubscription,
            step,
            reasons,
            selectedReason,
            cancelComment
        }
    }
}
</script>