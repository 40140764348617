<template>
    <div>
        <p class="exie-text-subtitle-1 font-weight-bold mt-10 mb-5">{{$t('accountSettings.billing.addonsTitle')}}</p> 
        <v-row>
            <v-col cols="12" md="4">
                <p class="exie-text-body-1">
                    {{$t('accountSettings.billing.addonsDescription')}}
                </p>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="4">
                <v-card height="100%" class="px-5 py-5" rounded="xl" variant="flat" color="grey-lighten-4" min-height="250px">
                    <p class="exie-text-subtitle-1 font-weight-bold">
                        <span>{{$t('accountSettings.billing.addons.2000.title')}}</span>
                    </p>
                    <p class="mt-3 exie-text-body-2">
                        <span>{{$t('accountSettings.billing.addons.2000.description')}}</span>
                    </p>
                    <p class="my-6">
                        <span class="exie-text-h3">$20</span>
                    </p>
                    <p>
                        <v-btn v-if="currentAccount.pro_status != 0" color="primary" size="large" block :disabled="loading" @click="handleBuyAddons(2000)">
                            {{$t('accountSettings.billing.addons.2000.btnAddTitle')}}
                        </v-btn>
                        <v-btn v-else color="primary" size="large" block>
                            {{$t('accountSettings.billing.addons.2000.btnAddTitle')}}
                            <UpgradeToProModal />
                        </v-btn>
                    </p>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card height="100%" class="px-5 py-5" rounded="xl" variant="flat" color="grey-lighten-4" min-height="250px">
                    <p class="exie-text-subtitle-1 font-weight-bold">
                        <span>{{$t('accountSettings.billing.addons.10000.title')}}</span>
                    </p>
                    <p class="mt-3 exie-text-body-2">
                        <span>{{$t('accountSettings.billing.addons.10000.description')}}</span>
                    </p>
                    <p class="my-6">
                        <span class="exie-text-h3">$85</span>
                    </p>
                    <p>
                        <v-btn v-if="currentAccount.pro_status != 0" color="primary" size="large" block :disabled="loading" @click="handleBuyAddons(10000)">
                            {{$t('accountSettings.billing.addons.10000.btnAddTitle')}}
                        </v-btn>
                        <v-btn v-else color="primary" size="large" block>
                            {{$t('accountSettings.billing.addons.10000.btnAddTitle')}}
                            <UpgradeToProModal />
                        </v-btn>
                    </p>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import api from '@/utils/api'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import UpgradeToProModal from './UpgradeToProModal.vue'
import { useAccountSubscriptionStore } from '@/stores/AccountSubscriptionStore'
import { useAppStateStore } from '@/stores/AppStateStore'
import { storeToRefs } from 'pinia'
export default {
    components: {UpgradeToProModal},
    setup() {
        const loading = ref(false)
        const route = useRoute()
        const accountId = route.params.account_id
        const appStateStore = useAppStateStore()

        const {currentAccount} = storeToRefs(appStateStore)

        const accountSubscriptionStore = useAccountSubscriptionStore()
        accountSubscriptionStore.init(accountId)
        const {subscription} = storeToRefs(accountSubscriptionStore)

        const handleBuyAddons = async (amount) => {
            if(![2000,10000].includes(amount)) {
                console.log('invalid amount')
                return
            }
            loading.value = true
            const response = await api.post(`/api/billing/${accountId}/addon-checkout-session`, {
                messages_amount: amount
            })

            loading.value = false

            if (response.error) {
                console.log(response.error)
                return
            }

            window.location.assign(response.url)
        } 

        return {
            loading,
            handleBuyAddons,
            subscription,
            currentAccount,
        }
    }
}
</script>