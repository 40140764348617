<template>
    <v-container class="pt-0">
        <v-stepper v-if="[0,1,2].includes(step)" :model-value="step" flat>
            <v-stepper-header class="elevation-0">
                <v-stepper-item :title="$t('onboarding.step1.title')" value="1" />
                <v-stepper-item :title="$t('onboarding.step2.title')" value="2" />
                <v-stepper-item :title="$t('onboarding.step3.title')" value="3" />
            </v-stepper-header>
            <v-row class="mb-3 mx-3">
                <v-col cols="12" md="9">
                    <p class="my-3 exie-text-h6 text-pre-line font-weight-regular">{{ $t('onboarding.trainingTitle') }}</p>
                </v-col>
            </v-row>
            <v-stepper-window>
                <v-stepper-window-item :transition="false" :reverse-transition="false" value="1">
                    <div class="py-1">
                        <p class="mb-2 exie-text-subtitle-1 font-weight-bold">{{ $t('onboarding.step1.aboutQuestion') }}</p>
                        <v-textarea 
                            @update:modelValue="saveAnswersDraft" 
                            v-model="aboutAnswer" 
                            rows="3" 
                            no-resize 
                            counter 
                            maxlength="10000" 
                            auto-grow
                            @update:focused="(value) => aboutAnswerPlaceholder = value ? $t('onboarding.step1.aboutQuestionPlaceholder') : $t('onboarding.clickToExample')"
                            :placeholder="aboutAnswerPlaceholder"
                        />
                    </div>
                    <div class="py-1">
                        <p class="mb-2 exie-text-subtitle-1 font-weight-bold">{{ $t('onboarding.step1.contactsQuestion') }}</p>
                        <v-textarea 
                            @update:modelValue="saveAnswersDraft" 
                            v-model="contactsAnswer" 
                            rows="3" 
                            no-resize 
                            counter 
                            maxlength="10000" 
                            auto-grow
                            @update:focused="(value) => contactsAnswerPlaceholder = value ? $t('onboarding.step1.contactsQuestionPlaceholder') : $t('onboarding.clickToExample')"
                            :placeholder="contactsAnswerPlaceholder"
                        />
                    </div>
                    <div class="py-1">
                        <p class="mb-2 exie-text-subtitle-1 font-weight-bold">{{ $t('onboarding.step1.promotionsQuestion') }}</p>
                        <v-textarea 
                            @update:modelValue="saveAnswersDraft" 
                            v-model="promotionsAnswer" 
                            rows="3" 
                            no-resize 
                            counter 
                            maxlength="10000" 
                            auto-grow
                            @update:focused="(value) => promotionsAnswerPlaceholder = value ? $t('onboarding.step1.promotionsQuestionPlaceholder') : $t('onboarding.clickToExample')"
                            :placeholder="promotionsAnswerPlaceholder"
                        />
                    </div>
                </v-stepper-window-item>
                <v-stepper-window-item :transition="false" :reverse-transition="false" value="2">
                    <div class="py-1">
                        <p class="mb-2 exie-text-subtitle-1 font-weight-bold">{{ $t('onboarding.step2.refundQuestion') }}</p>
                        <v-textarea 
                            @update:modelValue="saveAnswersDraft" 
                            v-model="refundAnswer" 
                            rows="3" 
                            no-resize 
                            counter 
                            maxlength="10000" 
                            auto-grow
                            @update:focused="(value) => refundAnswerPlaceholder = value ? $t('onboarding.step2.refundQuestionPlaceholder') : $t('onboarding.clickToExample')"
                            :placeholder="refundAnswerPlaceholder"
                        />
                    </div>
                    <div class="py-1">
                        <p class="mb-2 exie-text-subtitle-1 font-weight-bold">{{ $t('onboarding.step2.deliveryQuestion') }}</p>
                        <v-textarea 
                            @update:modelValue="saveAnswersDraft" 
                            v-model="deliveryAnswer" 
                            rows="3" 
                            no-resize 
                            counter 
                            maxlength="10000" 
                            auto-grow
                            @update:focused="(value) => deliveryAnswerPlaceholder = value ? $t('onboarding.step2.deliveryQuestionPlaceholder') : $t('onboarding.clickToExample')"
                            :placeholder="deliveryAnswerPlaceholder"
                        />
                    </div>
                    <div class="py-1">
                        <p class="mb-2 exie-text-subtitle-1 font-weight-bold">{{ $t('onboarding.step2.purchaseQuestion') }}</p>
                        <v-textarea 
                            @update:modelValue="saveAnswersDraft" 
                            v-model="purchaseAnswer" 
                            rows="3" 
                            no-resize 
                            counter 
                            maxlength="10000" 
                            auto-grow
                            @update:focused="(value) => purchaseAnswerPlaceholder = value ? $t('onboarding.step2.purchaseQuestionPlaceholder') : $t('onboarding.clickToExample')"
                            :placeholder="purchaseAnswerPlaceholder"
                        />
                    </div>
                </v-stepper-window-item>
                <v-stepper-window-item :transition="false" :reverse-transition="false" value="3">
                    <div class="py-1">
                        <p class="mb-2 exie-text-subtitle-1 font-weight-bold">{{ $t('onboarding.step3.servicesQuestion') }}</p>
                        <v-textarea 
                            :disabled="isTraining" 
                            @update:modelValue="saveAnswersDraft" 
                            v-model="servicesAnswer" 
                            rows="3" 
                            no-resize 
                            counter 
                            maxlength="10000" 
                            auto-grow
                            @update:focused="(value) => servicesAnswerPlaceholder = value ? $t('onboarding.step3.servicesQuestionPlaceholder') : $t('onboarding.clickToExample')"
                            :placeholder="servicesAnswerPlaceholder"
                        />
                    </div>
                    <div class="py-1">
                        <p class="mb-2 exie-text-subtitle-1 font-weight-bold">{{ $t('onboarding.step3.bookingQuestion') }}</p>
                        <v-textarea 
                            :disabled="isTraining" 
                            @update:modelValue="saveAnswersDraft" 
                            v-model="bookingAnswer" 
                            rows="3" 
                            no-resize 
                            counter 
                            maxlength="10000" 
                            auto-grow
                            @update:focused="(value) => bookingAnswerPlaceholder = value ? $t('onboarding.step3.bookingQuestionPlaceholder') : $t('onboarding.clickToExample')"
                            :placeholder="bookingAnswerPlaceholder"
                        />
                    </div>
                    <div class="py-1">
                        <p class="mb-2 exie-text-subtitle-1 font-weight-bold">{{ $t('onboarding.step3.otherQuestion') }}</p>
                        <v-textarea 
                            :disabled="isTraining" 
                            @update:modelValue="saveAnswersDraft" 
                            v-model="otherAnswer" 
                            rows="3" 
                            no-resize 
                            counter 
                            maxlength="10000" 
                            auto-grow
                            @update:focused="(value) => otherAnswerPlaceholder = value ? $t('onboarding.step3.otherQuestionPlaceholder') : $t('onboarding.clickToExample')"
                            :placeholder="otherAnswerPlaceholder"
                        />
                    </div>                               
                </v-stepper-window-item>
            </v-stepper-window>
            <div>
                <div v-if="step === 0" class="w-100 px-5">
                    <div v-if="smAndDown" class="w-100">
                        <div class="w-100 d-flex justify-space-between">
                            <v-btn disabled>{{ $t('onboarding.actions.btnBack') }}</v-btn>
                            <v-btn color="primary" @click="handleNextStep(0)">{{ $t('onboarding.actions.btnNext') }}</v-btn>
                        </div>
                        <v-divider class="my-5" />
                        <div class="w-100 d-flex justify-center">
                            <v-btn variant="text" @click="skipOnboarding">{{ $t('onboarding.actions.btnSkipOnboarding') }}</v-btn>
                        </div>
                    </div>
                    <div v-else class="d-flex w-100">
                        <v-btn class="mr-auto" variant="text" @click="skipOnboarding">{{ $t('onboarding.actions.btnSkipOnboarding') }}</v-btn>
                        <v-btn class="mr-3" disabled>{{ $t('onboarding.actions.btnBack') }}</v-btn>
                        <v-btn color="primary" @click="handleNextStep(0)">{{ $t('onboarding.actions.btnNext') }}</v-btn>
                    </div>
                </div>
                <div v-if="step === 1" class="d-flex w-100 px-5">
                    <div v-if="smAndDown" class="w-100">
                        <div class="w-100 d-flex justify-space-between">
                            <v-btn color="grey-lighten-3" @click="handlePrevStep(1)">{{ $t('onboarding.actions.btnBack') }}</v-btn>
                            <v-btn color="primary" @click="handleNextStep(1)">{{ $t('onboarding.actions.btnNext') }}</v-btn>
                        </div>
                        <v-divider class="my-5" />
                        <div class="w-100 d-flex justify-center">
                            <v-btn variant="text" @click="skipOnboarding">{{ $t('onboarding.actions.btnSkipOnboarding') }}</v-btn>
                        </div>
                    </div>
                    <div v-else class="d-flex w-100">
                        <v-btn class="mr-auto" variant="text" @click="skipOnboarding">{{ $t('onboarding.actions.btnSkipOnboarding') }}</v-btn>
                        <v-btn class="mr-3" color="grey-lighten-3" @click="handlePrevStep(1)">{{ $t('onboarding.actions.btnBack') }}</v-btn>
                        <v-btn color="primary" @click="handleNextStep(1)">{{ $t('onboarding.actions.btnNext') }}</v-btn>
                    </div>
                </div>
                <div v-if="step === 2" class="d-flex w-100 px-5">
                    <div v-if="smAndDown" class="w-100">
                        <div class="w-100 d-flex justify-space-between">
                            <v-btn color="grey-lighten-3" :disabled="isTraining" @click="handlePrevStep(2)">{{ $t('onboarding.actions.btnBack') }}</v-btn>
                            <v-btn color="primary" :disabled="isTraining" @click="handleTrain">{{ $t('onboarding.actions.btnFinish') }}</v-btn>
                        </div>
                        <v-divider class="my-5" />
                        <div class="w-100 d-flex justify-center">
                            <v-btn :disabled="isTraining" variant="text" @click="skipOnboarding">{{ $t('onboarding.actions.btnSkipOnboarding') }}</v-btn>
                        </div>
                    </div>
                    <div v-else class="d-flex w-100">
                        <v-btn class="mr-auto" :disabled="isTraining" variant="text" @click="skipOnboarding">{{ $t('onboarding.actions.btnSkipOnboarding') }}</v-btn>
                        <v-btn class="mr-3" color="grey-lighten-3" :disabled="isTraining" @click="handlePrevStep(2)">{{ $t('onboarding.actions.btnBack') }}</v-btn>
                        <v-btn color="primary" :disabled="isTraining" @click="handleTrain">{{ $t('onboarding.actions.btnFinish') }}</v-btn>
                    </div>
                    
                </div>
            </div>
            <v-progress-linear class="mt-3" v-if="isTraining" color="primary" indeterminate></v-progress-linear>
        </v-stepper>
        <div v-if="step == 3">
            <div>
                <h4 class="exie-text-h4">{{ $t('onboarding.step4.title') }}</h4>
                <v-row class="mt-3">
                    <v-col cols="12" md="7">
                        <p class="exie-text-body-1">{{ $t('onboarding.step4.description') }}</p>
                    </v-col>
                </v-row>
                <p class="mt-5 mb-1 exie-text-subtitle-1 font-weight-bold">{{ $t('onboarding.step4.questionsExampleTitle') }}</p>
                <v-row class="exie-text-body-1">
                    <v-col cols="12" sm="6" md="5">
                        <ul class="ml-5">
                            <li>{{ $t('onboarding.step4.questionExample1') }}</li>
                            <li>{{ $t('onboarding.step4.questionExample2') }}</li>
                            <li>{{ $t('onboarding.step4.questionExample3') }}</li>
                        </ul>
                    </v-col>
                    <v-col cols="12" sm="6" md="5">
                        <ul class="ml-5">
                            <li>{{ $t('onboarding.step4.questionExample4') }}</li>
                            <li>{{ $t('onboarding.step4.questionExample5') }}</li>
                        </ul>
                    </v-col>
                </v-row>
                <div class="my-3">
                    <PlayChat messagesContainerMaxHeight="350px" :account_id="accountId" onboardingMode="true" />
                </div>
                <div class="d-flex w-100 align-center justify-center">
                    <v-btn class="mt-3" size="large" color="grey-lighten-4" @click="finishOnboarding">{{ $t('onboarding.actions.btnGoToAccount') }}</v-btn>
                </div>
            </div>
        </div>
        <v-snackbar close-on-content-click="1" location="top right" color="error" v-model="error" :timeout="8000">
            {{ errorMessage }}
        </v-snackbar>
        <v-snackbar close-on-content-click="1" location="top right" color="success" v-model="success" :timeout="8000">
            {{ successMessage }}
        </v-snackbar>
    </v-container>
</template>

<script>
import { ref } from 'vue'
import PlayChat from '@/components/playground/PlayChat.vue'
import { useI18n } from "vue-i18n"
import { useDisplay } from 'vuetify'
import api from '@/utils/api'
import { useAppStateStore } from '@/stores/AppStateStore'
import { useRouter } from 'vue-router'
import { useMixPanelStore } from '@/stores/MixPanelStore'

export default {
    components: {PlayChat},
    setup() {
        const {t} = useI18n()
        const appStateStore = useAppStateStore()
        const router = useRouter()

        const mixPanelStore = useMixPanelStore()

        const {smAndDown} = useDisplay()


        const step = ref(appStateStore.currentOnboardingStep)

        const accountId = appStateStore.currentAccount.id
        
        //v-snacks
        const error = ref(false)
        const errorMessage = ref(null)
        const success = ref(false)
        const successMessage = ref(null)
        
        //Training answers
        const aboutAnswer = ref(null)
        const aboutAnswerPlaceholder = ref(t('onboarding.clickToExample'))
        const contactsAnswer = ref(null)
        const contactsAnswerPlaceholder = ref(t('onboarding.clickToExample'))
        const promotionsAnswer = ref(null)
        const promotionsAnswerPlaceholder = ref(t('onboarding.clickToExample'))
        const refundAnswer = ref(null)
        const refundAnswerPlaceholder = ref(t('onboarding.clickToExample'))
        const deliveryAnswer = ref(null)
        const deliveryAnswerPlaceholder = ref(t('onboarding.clickToExample'))
        const purchaseAnswer = ref(null)
        const purchaseAnswerPlaceholder = ref(t('onboarding.clickToExample'))
        const servicesAnswer = ref(null)
        const servicesAnswerPlaceholder = ref(t('onboarding.clickToExample'))
        const bookingAnswer = ref(null)
        const bookingAnswerPlaceholder = ref(t('onboarding.clickToExample'))
        const otherAnswer = ref(null)
        const otherAnswerPlaceholder = ref(t('onboarding.clickToExample'))

        //Call operator
        const callOperatorText = ref(t('onboarding.step4.callOperatorTextDefaultValue'))
        const callOperatorResponse = ref(t('onboarding.step4.callOperatorResponseDefaultValue'))

        const saveStep = async () => {
            const responseData = await api.post(`/api/onboarding/step/${accountId}`, {
                step: step.value
            })
            if (responseData.error) {
                return
            }

            appStateStore.updateUser(responseData)
        }

        const handleNextStep = async (val) => {
            step.value = val + 1
            await saveStep()
        }

        const handlePrevStep = async (val) => {
            step.value = val - 1
            await saveStep()
        }

        const loadDraft = async() => {
            const responseData = await api.get(`/api/onboarding/draft/${accountId}`)
            if (!responseData.draft) {
                return
            }
            aboutAnswer.value = responseData.draft.aboutAnswer ?? null
            contactsAnswer.value = responseData.draft.contactsAnswer ?? null
            promotionsAnswer.value = responseData.draft.promotionsAnswer ?? null
            refundAnswer.value = responseData.draft.refundAnswer ?? null
            deliveryAnswer.value = responseData.draft.deliveryAnswer ?? null
            purchaseAnswer.value = responseData.draft.purchaseAnswer ?? null
            servicesAnswer.value = responseData.draft.servicesAnswer ?? null
            bookingAnswer.value = responseData.draft.bookingAnswer ?? null
            otherAnswer.value = responseData.draft.otherAnswer ?? null
        }
        loadDraft()

        const isTraining = ref(false)
        const handleTrain = async () => {
            if (
                !aboutAnswer.value &&
                !contactsAnswer.value &&
                !promotionsAnswer.value &&
                !refundAnswer.value &&
                !deliveryAnswer.value &&
                !purchaseAnswer.value &&
                !servicesAnswer.value &&
                !bookingAnswer.value &&
                !otherAnswer.value
            ) {
                error.value = true
                errorMessage.value = t('onboarding.notify.emptyContext')
                return
            }

            isTraining.value = true

            //create QnA
            const qnaList = [
                {
                    question: t('onboarding.step1.aboutQuestion'),
                    answer: aboutAnswer.value
                },
                {
                    question: t('onboarding.step1.contactsQuestion'),
                    answer: contactsAnswer.value
                },
                {
                    question: t('onboarding.step1.promotionsQuestion'),
                    answer: promotionsAnswer.value
                },
                {
                    question: t('onboarding.step2.refundQuestion'),
                    answer: refundAnswer.value
                },
                {
                    question: t('onboarding.step2.deliveryQuestion'),
                    answer: deliveryAnswer.value
                },
                {
                    question: t('onboarding.step2.purchaseQuestion'),
                    answer: purchaseAnswer.value
                },
                {
                    question: t('onboarding.step3.servicesQuestion'),
                    answer: servicesAnswer.value
                },
                {
                    question: t('onboarding.step3.bookingQuestion'),
                    answer: bookingAnswer.value
                },
                {
                    question: t('onboarding.step3.otherQuestion'),
                    answer: otherAnswer.value
                }
            ]
            
            const responseData = await api.post(`/api/onboarding/create-qna-indexes/${accountId}`, {
                qna_list: qnaList.filter((qna) => qna.answer)
            })

            if(responseData.error) {
                error.value = true
                errorMessage.value = responseData.error

                isTraining.value = false
                return
            }

            success.value = true
            successMessage.value = t('onboarding.notify.successTrain')
            step.value++
            await saveStep()

            isTraining.value = false
        }

        let saveDraftTimeout = null
        const saveAnswersDraft = () => {
            if (saveDraftTimeout) {
                clearTimeout(saveDraftTimeout)
            }

            saveDraftTimeout = setTimeout(() => {
                const responseData = api.post(`/api/onboarding/draft/${accountId}`, {
                    draft: {
                        aboutAnswer: aboutAnswer.value,
                        contactsAnswer: contactsAnswer.value,
                        promotionsAnswer: promotionsAnswer.value,
                        refundAnswer: refundAnswer.value,
                        deliveryAnswer: deliveryAnswer.value,
                        purchaseAnswer: purchaseAnswer.value,
                        servicesAnswer: servicesAnswer.value,
                        bookingAnswer: bookingAnswer.value,
                        otherAnswer: otherAnswer.value
                    }
                })
            }, 1000)
        }

        const saveCallBtn = async () => {
            if(!callOperatorText.value || !callOperatorResponse.value) {
                error.value = true
                errorMessage.value = t('accountSettings.general.bot.notify.callOperatorEmpty')
                return 
            }
            const responseData = await api.post(`/api/account/call-operator-text/${accountId}`, {
                call_operator_text: callOperatorText.value, 
                call_operator_response: callOperatorResponse.value
            })

            if(responseData.error) {
                error.value = true
                errorMessage.value = responseData.error

                return
            }

            appStateStore.updateCurrentAccount(responseData, true)
            step.value++
            await saveStep()
        }

        const skipOnboarding = async () => {
            mixPanelStore.track('click_skip_onboarding')
            const responseData = await api.post(`/api/onboarding/skip/${accountId}`)
            appStateStore.updateCurrentAccount(responseData, true)
            router.push({
                name: 'dashboard',
                params: {
                    account_id: appStateStore.currentAccount.id
                }
            })
        }

        const finishOnboarding = async () => {
            const responseData = await api.post(`/api/onboarding/finish/${accountId}`)
            appStateStore.updateCurrentAccount(responseData, true)
            router.push({
                name: 'dashboard',
                params: {
                    account_id: appStateStore.currentAccount.id
                }
            })
        }

        return { 
            step, 
            handleNextStep, 
            handlePrevStep,
            aboutAnswer, 
            aboutAnswerPlaceholder, 
            contactsAnswer,
            contactsAnswerPlaceholder,
            promotionsAnswer,
            promotionsAnswerPlaceholder,
            refundAnswer,
            refundAnswerPlaceholder,
            deliveryAnswer,
            deliveryAnswerPlaceholder,
            purchaseAnswer,
            purchaseAnswerPlaceholder,
            servicesAnswer,
            servicesAnswerPlaceholder,
            bookingAnswer,
            bookingAnswerPlaceholder,
            otherAnswer,
            otherAnswerPlaceholder,
            callOperatorText,
            callOperatorResponse,
            handleTrain,
            saveAnswersDraft,
            isTraining,
            error,
            errorMessage,
            success,
            successMessage,
            skipOnboarding,
            finishOnboarding,
            saveCallBtn,
            accountId,
            smAndDown
        }
    }
}
</script>

<style scoped>
.out {
    border-radius: 10px;
    background: rgba(24,103,192);
    align-self: flex-end;
    color: white;
    text-align: right;
    max-width: 250px;
    min-width: 100px;
    padding: 10px 20px;
    margin: 10px;
}
</style>