import { defineStore } from 'pinia'
import api from '@/utils/api'


export const useAccountInvitationsStore = defineStore('accountInvitationsStore', { 
    state: () => ({
        invitations: []
    }),
    actions: {
        async init(accountId) {
            const response = await api.get(`/api/team/${accountId}/invitations`)
            this.invitations = response
        },
        addInvitation(invitation) {

            const invitationExists = this.invitations.find((inv) => inv.id == invitation.id)
            if(!invitationExists) {
                this.invitations.push(invitation)
            }
        },
        deleteInvitation(invitation) {
            this.invitations = this.invitations.filter((inv) => inv.id != invitation.id)
        }
    }
})