<template>
    <div class="mt-5">
        <span class="font-weight-bold">{{ $t('sources.qna.title') }}</span>
    </div>
    <div class="mt-3 exie-text-body-1">
        <v-row>
            <v-col cols="12" md="6">
                {{ $t('sources.qna.description') }}
            </v-col>
        </v-row>
        
    </div>
    <p class="font-weight-bold mt-10 mb-3">{{$t('sources.qna.addQnACount')}} {{ documentsStore.documentsQnaCount }} / {{MAX_QNA_COUNT}}</p>
    <v-sheet width="100%">
        <div class="mb-5">
            <v-text-field rounded="lg" maxlength="10000" counter :error="questionError" :error-messages="questionError" :label="$t('sources.qna.questionLabel')" variant="solo-filled" flat v-model="question"></v-text-field>
            <v-textarea class="mt-1" rounded="lg" maxlength="10000" counter rows="5" no-resize :error="answerError" :error-messages="answerError" v-model="answer" :label="$t('sources.qna.answerLabel')" flat variant="solo-filled"></v-textarea>
            <p class="d-flex justify-end mt-3">
                <v-banner v-if="documentsStore.documentsQnaCount >= MAX_QNA_COUNT" :text="$t('sources.qna.notify.limitReached')"></v-banner>
                <v-btn @click="handleAddToIndex" :disabled="(trainingStatus === 'training') || documentsStore.documentsQnaCount >= MAX_QNA_COUNT" color="primary">{{$t('sources.qna.btnAddToIndex')}}</v-btn>
            </p>
        </div>

        <div v-if="documentsStore.documentsQna.length">
            <p class="font-weight-bold mt-10 mb-3">{{$t('sources.qna.alreadyIndexed')}} {{ documentsStore.documentsQnaCount }} / {{MAX_QNA_COUNT}}</p>
    
            <v-card rounded="lg" variant="flat" class="border border-thin pa-5 my-3" v-for="document in documentsStore.documentsQna">
                <v-card-title>{{ document.meta.question }}</v-card-title>
                <v-card-text>{{ document.meta.answer }}</v-card-text>
                <p class="d-flex justify-space-between">
                    <v-btn variant="text" @click="handleShowDeleteModal(document.id, document.meta.question, document.meta.answer)">{{$t('sources.qna.btnDelete')}}</v-btn>
                    <v-btn variant="flat" color="grey-lighten-4" @click="handleShowEditModal(document.id, document.meta.question, document.meta.answer)">{{$t('sources.qna.btnEdit')}}</v-btn>
                </p>
            </v-card>
        </div>
    </v-sheet>
    <v-snackbar location="top right" color="success" v-model="successfullyIndexed" :timeout="2000">
        {{$t('sources.qna.notify.successIndex')}}
    </v-snackbar>
    <v-snackbar location="top right" color="error" v-model="errorIndexed" :timeout="2000">
        {{$t('sources.qna.notify.errorIndex')}}
    </v-snackbar>
    <v-snackbar location="top right" color="warning" v-model="successfullyDeleted" :timeout="2000">
        {{$t('sources.qna.notify.indexDeleted')}}
    </v-snackbar>
    <!--Update QnA modal-->
    <v-dialog
      v-model="showEditModal"
      width="800px"
    >
      <v-card
        max-width="800"
        class="px-2"
        rounded="lg"
      >
        <p class="mt-2 mr-2 text-right">
            <v-btn size="x-small" color="grey-lighten-4" flat :icon="mdiClose" @click="handleCloseEditModal"></v-btn>
        </p>
        <p class="px-5 text-center exie-text-h5">{{$t('sources.qna.editTitle')}}</p>
        <div class="pa-5">
            <v-text-field rounded="lg" maxlength="10000" counter :label="$t('sources.qna.questionLabel')" variant="solo-filled" flat v-model="updatedQuestion"></v-text-field>
            <v-textarea class="mt-1" rounded="lg" maxlength="10000" counter rows="5" no-resize v-model="updatedAnswer" :label="$t('sources.qna.answerLabel')" flat variant="solo-filled"></v-textarea>
        </div>
        <p class="d-flex justify-space-between pa-5">
            <v-btn variant="text" @click="handleCloseEditModal()">{{$t('sources.qna.btnEditClose')}}</v-btn>
            <v-btn variant="flat" color="primary" @click="handleUpdateQna()">{{$t('sources.qna.btnEditSave')}}</v-btn>
        </p>
      </v-card>
    </v-dialog>
    <!--Delete qna modal-->
    <v-dialog
      v-model="showDeleteModal"
      width="516"
    >
      <v-card
        max-width="516"
        rounded="lg"
        class="px-2"
      >
        <p class="mt-2 mr-2 text-right">
            <v-btn size="x-small" color="grey-lighten-4" flat :icon="mdiClose" @click="handleCloseDeleteModal"></v-btn>
        </p>
        <p class="px-5 text-center exie-text-h5">{{$t('sources.qna.deleteTitle')}}</p>
        <div class="pa-5 my-8">
            <p class="text-center exie-text-body-1">{{$t('sources.qna.deleteDescription')}}</p>
        </div>
        <p class="d-flex justify-space-between pa-5">
            <v-btn variant="flat" color="grey-lighten-4" @click="handleCloseDeleteModal()">{{$t('sources.qna.btnDeleteClose')}}</v-btn>
            <v-btn variant="text" color="red" @click="handleDelete()">{{$t('sources.qna.btnDeleteConfirm')}}</v-btn>
        </p>
      </v-card>
    </v-dialog>
</template>

<script>
import { ref } from 'vue';
import { useRoute } from 'vue-router'
import { useDocumentsStore } from '@/stores/DocumentsStore'
import { useI18n } from "vue-i18n"
import api from '@/utils/api'
import { mdiClose } from '@mdi/js'

const MAX_QNA_COUNT = 100

export default {
    setup() {

        const route = useRoute()
        const accountId = route.params.account_id

        const {t} = useI18n()
        const question = ref(null)
        const questionError = ref(null)
        const answer = ref(null)
        const answerError = ref(null)
        const documentsStore = useDocumentsStore()
        documentsStore.loadDocuments(accountId)

        const successfullyIndexed = ref(false)
        const successfullyDeleted = ref(false)
        const errorIndexed = ref(false)

        const trainingStatus = ref('start')

        const showEditModal = ref(false)
        const updatedDocumentId = ref(null)
        const updatedQuestion = ref(null)
        const updatedAnswer = ref(null)

        const showDeleteModal = ref(false)
        const deletedDocumentId = ref(null)
        const deletedQuestion = ref(null)
        const deletedAnswer = ref(null)

        const handleShowEditModal = (documentId, question, answer) => {
            showEditModal.value = true
            updatedDocumentId.value = documentId
            updatedQuestion.value = question
            updatedAnswer.value = answer
        }

        const handleCloseEditModal = () => {
            showEditModal.value = false
            updatedDocumentId.value = null
            updatedQuestion.value = null
            updatedAnswer.value = null
        }

        const handleUpdateQna = async () => {
            if(!updatedDocumentId.value
                || !updatedQuestion.value
                || !updatedAnswer.value
            ) {
                return
            }

            const response = await api.put(`/api/ai/${accountId}/update-qna/${updatedDocumentId.value}`, {
                question: updatedQuestion.value,
                answer: updatedAnswer.value,
            })

            if(response.error) {
                errorIndexed.value = true
                return
            }

            documentsStore.updateDocument(response)
            handleCloseEditModal()
        }


        const handleAddToIndex = async () => {
            questionError.value = null
            answerError.value = null
            if (!question.value) {
                questionError.value = t('sources.qna.notify.questionRequired')
            }
            if (!answer.value) {
                answerError.value = t('sources.qna.notify.answerRequired')
            }
            if (!question.value || !answer.value) {
                return
            }

            trainingStatus.value = 'training'
            
            const responseData = await api.post(`/api/ai/create-index-from-qna/${accountId}`, {
                question: question.value, 
                answer: answer.value
            })

            if(responseData.error) {
                trainingStatus.value = 'error'
                errorIndexed.value = true
            } else {
                documentsStore.addDocumentToStore(responseData)
                question.value = null
                answer.value = null
                trainingStatus.value = 'start'
                successfullyIndexed.value = true
            }
        }

        const handleDelete = async () => {
            if (!deletedDocumentId.value) {
                return
            }
            const responseData = await api.delete(`/api/documents/${accountId}/${deletedDocumentId.value}`)
            if(responseData.status === 'success') {
                documentsStore.deleteDocumentFromStore(deletedDocumentId.value)
                successfullyDeleted.value = true
            }

            handleCloseDeleteModal()
        }

        const handleShowDeleteModal = (documentId, question, answer) => {
            showDeleteModal.value = true
            deletedDocumentId.value = documentId
            deletedQuestion.value = question
            deletedAnswer.value = answer
        }

        const handleCloseDeleteModal = () => {
            showDeleteModal.value = false
            deletedDocumentId.value = null
            deletedQuestion.value = null
            deletedAnswer.value = null
        }

        return { 
            question, 
            answer, 
            questionError, 
            answerError, 
            trainingStatus, 
            handleAddToIndex, 
            documentsStore, 
            handleDelete, 
            successfullyIndexed, 
            errorIndexed,
            successfullyDeleted,
            MAX_QNA_COUNT,
            handleShowEditModal,
            handleCloseEditModal,
            handleUpdateQna,
            showEditModal,
            updatedAnswer,
            updatedQuestion,
            showDeleteModal,
            handleShowDeleteModal,
            handleCloseDeleteModal,
            deletedAnswer,
            deletedQuestion,
            mdiClose
         }
    }
}
</script>