<template>
    <div class="mb-5">
        <p class="font-weight-bold mb-3">{{$t('sources.text.documents.alreadyIndexed')}} {{ documentsStore.documentsTextCount }} / {{ maxTextCount }}</p>
        <v-sheet class="border border-thin rounded-lg py-2 px-3 my-2" v-for="document in documentsStore.documentsText" :key="document.id">
            <div class="d-inline-block text-truncate" style="max-width: 50%;">
                {{ document?.meta?.name ?? document.document_ref_id }} ({{ formatDate(document.ts_last_update) }})
            </div>
            <div class="d-inline-block float-right">
                <v-btn variant="text" size="x-small" class="ms-3" @click="handleShowEditModal(document.id)">{{$t('sources.text.documents.btnEdit')}}</v-btn>
                <v-btn color="error" variant="text" size="x-small" class="ms-3" @click="handleShowDeleteModal(document.id, document.document_ref_id)">{{$t('sources.text.documents.btnDelete')}}</v-btn>
            </div>
        </v-sheet>
    </div>
    <v-snackbar location="top right" color="error" v-model="successfullyDeleted" :timeout="2000">
        {{$t('sources.text.documents.notify.successDelete')}}
    </v-snackbar>

    <!--Edit document modal-->
    <v-dialog
      v-model="showEditModal"
      width="800px"
    >
      <v-card
        max-width="800"
        rounded="lg"
      >
        <p class="mt-2 mr-2 text-right">
            <v-btn size="x-small" color="grey-lighten-4" flat :icon="mdiClose" @click="handleCloseEditModal"></v-btn>
        </p>
        <p class="px-5 text-center exie-text-h5">{{$t('sources.text.documents.editTitle')}}</p>
        <div class="pa-5">
            <v-text-field :loading="editDocumentLoading" rounded="lg" maxlength="50" counter :label="$t('sources.text.documents.documentNameLabel')" variant="solo-filled" flat v-model="editDocumentName"></v-text-field>
            <v-textarea hide-details :loading="editDocumentLoading" rounded="lg" counter rows="10" no-resize v-model="editDocumentText" :label="$t('sources.text.documents.documentTextLabel')" flat variant="solo-filled"></v-textarea>
        </div>
        <p class="d-flex justify-space-between pa-5">
            <v-btn variant="flat" color="grey-lighten-4" @click="handleCloseEditModal()">
                {{$t('sources.text.documents.btnSaveClose')}}
            </v-btn>
            <v-btn variant="flat" color="primary" 
                :disabled="((editDocumentLoaded?.data == editDocumentText) && editDocumentLoaded?.meta?.name == editDocumentName) || editDocumentSaving"
                @click="handleUpdateDocument()">
                    {{$t('sources.text.documents.btnSaveConfirm')}}
            </v-btn>
        </p>
      </v-card>
    </v-dialog>

    <!--Delete document modal-->
    <v-dialog
      v-model="showDeleteModal"
      width="516px"
    >
      <v-card
        max-width="516"
        rounded="lg"
      >
        <p class="mt-2 mr-2 text-right">
            <v-btn size="x-small" color="grey-lighten-4" flat :icon="mdiClose" @click="handleCloseDeleteModal"></v-btn>
        </p>
        <p class="px-5 text-center exie-text-h5">{{$t('sources.text.documents.deleteTitle')}}</p>
        <div class="pa-5 my-8">
            <p class="text-center exie-text-body-1">{{$t('sources.text.documents.deleteDescription')}}</p>
        </div>
        <p class="d-flex justify-space-between pa-5">
            <v-btn variant="flat" color="grey-lighten-4" @click="handleCloseDeleteModal()">{{$t('sources.text.documents.btnDeleteClose')}}</v-btn>
            <v-btn variant="text" color="red" @click="handleDelete()">{{$t('sources.text.documents.btnDeleteConfirm')}}</v-btn>
        </p>
      </v-card>
    </v-dialog>

    <v-snackbar location="top right" color="success" v-model="successfullyIndexed" :timeout="4000">
        {{$t('sources.text.notify.successIndex')}}
    </v-snackbar>
    <v-snackbar location="top right" color="error" v-model="errorIndexed" :timeout="4000">
        {{$t('sources.text.notify.errorIndex')}}
    </v-snackbar>
</template>

<script>
import { ref } from 'vue';
import { useDocumentsStore } from '@/stores/DocumentsStore.js'
import api from '@/utils/api'
import { mdiClose } from '@mdi/js'

export default {
    components: {},
    props: ['accountId', 'maxTextCount'],
    setup(props) {
        const successfullyDeleted = ref(false)
        const documents = ref([])

        const documentsStore = useDocumentsStore()
        documentsStore.loadDocuments(props.accountId)

        const showDeleteModal = ref(false)
        const deletedDocumentId = ref(null)
        const deletedDocumentName = ref(null)

        const showEditModal = ref(false)
        const editDocumentLoading = ref(false)
        const editDocumentId = ref(null)
        const editDocumentName = ref(null)
        const editDocumentLoaded = ref(null)
        const editDocumentText = ref(null)
        const editDocumentSaving = ref(false)

        const successfullyIndexed = ref(false)
        const errorIndexed = ref(false)

        const handleShowDeleteModal = (documentId, documentName) => {
            showDeleteModal.value = true
            deletedDocumentId.value = documentId,
            deletedDocumentName.value = documentName
        }

        const handleCloseDeleteModal = () => {
            showDeleteModal.value = false
            deletedDocumentId.value = null
            deletedDocumentName.value = null
        }

        const handleDelete = async () => {
            if(!deletedDocumentId.value) {
                return
            }
            const responseData = await api.delete(`/api/documents/${props.accountId}/${deletedDocumentId.value}`)
            if(responseData.status === 'success') {
                documentsStore.deleteDocumentFromStore(deletedDocumentId.value)
                successfullyDeleted.value = true
            }

            handleCloseDeleteModal()
        }

        const handleShowEditModal = async (documentId) => {
            showEditModal.value = true
            editDocumentLoading.value = true
            editDocumentId.value = documentId
            const response = await api.get(`/api/documents/${props.accountId}/${editDocumentId.value}`)
            editDocumentLoading.value = false
            if(response.error) {
                console.log(error)
                return
            }
            editDocumentLoaded.value = response
            editDocumentText.value = response.data
            editDocumentName.value = response?.meta?.name ?? response.data.substring(0,30)+'...' 
        }

        const handleCloseEditModal = () => {
            showEditModal.value = false
            editDocumentLoaded.value = null
            editDocumentId.value = null
            editDocumentName.value = null
            editDocumentText.value = null
        }

        const handleUpdateDocument = async() => {
            editDocumentSaving.value = true
            const response = await api.put(`/api/ai/${props.accountId}/update-text/${editDocumentId.value}`, {
                document: editDocumentText.value,
                name: editDocumentName.value
            })
            editDocumentSaving.value = false
            if(response.error) {
                console.log(error)
                errorIndexed.value = true
                return
            }

            documentsStore.reloadDocuments(props.accountId)
            successfullyIndexed.value = true
            handleCloseEditModal()
        }

        const formatDate = (date) => {
            const options = {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                hour12: false,
            }
            const dateParsed = Date.parse(date)
            return new Intl.DateTimeFormat(undefined, options).format(dateParsed)
        }

        return { 
            documents, 
            handleDelete, 
            documentsStore, 
            formatDate, 
            successfullyDeleted,
            handleCloseDeleteModal,
            handleShowDeleteModal,
            showDeleteModal,
            deletedDocumentName,
            mdiClose,
            editDocumentLoading,
            handleShowEditModal,
            handleCloseEditModal,
            editDocumentName,
            editDocumentText,
            showEditModal,
            editDocumentLoaded,
            handleUpdateDocument,
            editDocumentSaving,
            successfullyIndexed,
            errorIndexed
        }
    }
}
</script>

<style scoped>
.document-row {
    border-style: outset;
    margin: 5px;
}

</style>