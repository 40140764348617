import { createI18n } from "vue-i18n"
import en from './locales/en.json'
import es from './locales/es.json'
import hi from './locales/hi.json'
import fr from './locales/fr.json'
import pt from './locales/pt.json'
import de from './locales/de.json'


const locales = [{ en: en }, { es: es }, { hi: hi }, { pt: pt }, { fr: fr }, { de: de }]

function loadLocaleMessages() {
    const messages = {};
    locales.forEach((lang) => {
        const key = Object.keys(lang);
        messages[key] = lang[key];
    });
    return messages;
}

function getDefaultLocale() {
    let locale = 'en'
    const userLocale = localStorage.getItem('app_locale') || navigator.language.split('-')[0]
    
    locales.forEach((loc) => {
        if(loc[userLocale]) {
            locale = userLocale
        }
    })

    return locale
}

export default createI18n({
    locale: getDefaultLocale(),
    fallbackLocale: 'en',
    legacy: false,
    messages: loadLocaleMessages(),
  });