<template>
    <v-sheet width="100%">
        <Bot />
        <v-divider class="my-8" />
        <Instagram />
        <v-divider class="my-8" />
        <PushNotifications />
        <v-divider class="my-8" />
        <DeleteAccount v-if="appStateStore.isCurrentAccountOwner" />
        <v-divider v-if="appStateStore.isCurrentAccountOwner" class="my-8" />
        <div class="d-flex justify-end">
            <span @click="handleRestartOnboarding" class="text-disabled text-capitalize text-decoration-underline ma-2 cursor-pointer">{{ $t('accountSettings.general.btnRestartOnboarding') }}</span>
        </div>
    </v-sheet>
</template>

<script>
import Instagram from './general/Instagram.vue'
import Bot from './general/Bot.vue'
import PushNotifications from './general/PushNotifications.vue'
import DeleteAccount from './general/DeleteAccount.vue'
import api from '@/utils/api'
import { useAppStateStore } from '@/stores/AppStateStore'
import { useRoute } from 'vue-router'
export default {
    components: { Instagram, Bot, PushNotifications, DeleteAccount },
    setup() {
        const appStateStore = useAppStateStore()
        const route = useRoute()
        const handleRestartOnboarding = async () => {
            const responseData = await api.post(`/api/onboarding/restart/${route.params.account_id}`)
            appStateStore.updateCurrentAccount(responseData, true)
        }

        return {handleRestartOnboarding, appStateStore}
    }
}
</script>