<template>
    <div class="mt-3">
        <v-container class="d-flex justify-space-between exie-text-caption text-medium-emphasis">
            <v-row class="text-center pb-1">
                <v-col md="2" class="pa-0 pt-1">
                    <span class="">support@exie.app</span>
                </v-col>
                <v-col cols="12" md="8" class="pa-0">
                    <v-chip class="text-capitalize text-medium-emphasis cursor-pointer" size="small" variant="text" @click="handleClickPP">{{ $t('footer.privacyPolicy') }}</v-chip>
                    <v-chip class="text-capitalize text-medium-emphasis cursor-pointer" size="small" variant="text" @click="handleClickTos">{{ $t('footer.tos') }}</v-chip>
                    <PrivacySettingsModal />
                </v-col>
                <v-col md="2" class="pa-0 pt-1">
                    Exie App LLC
                </v-col>
            </v-row>
        </v-container>
    </div>
    <ConsentBanner />
</template>

<script>
import ConsentBanner from '@/components/cookieConsent/ConsentBanner.vue'
import PrivacySettingsModal from './cookieConsent/PrivacySettingsModal.vue';
export default {
    components: {ConsentBanner, PrivacySettingsModal},
    setup() {
        const handleClickPP = () => {
            window.open('https://legal.exie.app/privacy-policy','_blank')
        }

        const handleClickTos = () => {
            window.open('https://legal.exie.app/tos','_blank')
        }

        return {handleClickPP, handleClickTos}
    }
}
</script>
