<template>
    <div class="mt-5">
        <span class="font-weight-bold">{{ $t('sources.text.title') }}</span>
    </div>
    <div class="mt-3 exie-text-body-1">
        <v-row>
            <v-col cols="12" md="6">
                {{ $t('sources.text.description') }}
            </v-col>
        </v-row>
        
    </div>
    <div class="mt-10">
        <Documents :accountId="accountId" :maxTextCount="MAX_TEXT_COUNT" />
    </div>
    <v-sheet width="100%">
        <v-textarea 
            variant="solo-filled" 
            flat
            counter 
            rounded="lg"
            v-model="trainTextRef" 
            :placeholder="$t('sources.text.textPlaceholder')" 
            rows="8"
            class="mt-3"
            no-resize
            maxlength="200000"
            :disabled="trainingStatus.value === 'training' || parsingUrl.value"
        >
        </v-textarea>
        <p class="text-right mt-3">
            <v-btn :disabled="documentsStore.documentsTextCount >= MAX_TEXT_COUNT || !trainTextRef" v-if="trainingStatus === 'start'" color="primary" @click="handleTrainButton">{{$t('sources.text.btnTrain')}}</v-btn>
            <v-btn v-else-if="trainingStatus === 'training'" color="primary" disabled >{{$t('sources.text.btnTraining')}}</v-btn>
            <v-btn :disabled="documentsStore.documentsTextCount >= MAX_TEXT_COUNT" v-else-if="trainingStatus === 'error'" color="warning" @click="handleTrainButton">{{$t('sources.text.btnTryAgain')}}</v-btn>
            <p class="exie-text-caption mt-2" v-if="documentsStore.documentsTextCount >= MAX_TEXT_COUNT">{{$t('sources.text.notify.limitReached')}}</p>
        </p>
    </v-sheet>
    <v-snackbar location="top right" color="success" v-model="successfullyIndexed" :timeout="4000">
        {{$t('sources.text.notify.successIndex')}}
    </v-snackbar>
    <v-snackbar location="top right" color="error" v-model="errorIndexed" :timeout="4000">
        {{$t('sources.text.notify.errorIndex')}} {{errorMessage}}
    </v-snackbar>
</template>

<script>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import Documents from '@/components/Documents.vue';
import { useDocumentsStore } from '@/stores/DocumentsStore'
import api from '@/utils/api'
import { _ } from 'core-js';

const MAX_TEXT_COUNT = 10

export default {
    components: { Documents },
    setup() {
        const trainTextRef = ref(null)
        const trainingStatus = ref('start')
        const route = useRoute()
        const successfullyIndexed = ref(false)
        const errorIndexed = ref(false)
        const errorMessage = ref(null)

        const parsingUrl = ref(false)

        const urlForParsing = ref(null)

        const accountId = route.params.account_id
        const documentsStore = useDocumentsStore()
        documentsStore.loadDocuments(accountId)

        const handleTrainButton = async() => {
            trainingStatus.value = 'training'

            const responseData = await api.post(`/api/ai/create-index-from-text/${accountId}`, {
                document: trainTextRef.value
            })

            if(responseData.error) {
                trainingStatus.value = 'error'
                errorIndexed.value = true
                errorMessage.value = responseData.error
            } else {
                documentsStore.addDocumentToStore(responseData)
                trainTextRef.value = null
                trainingStatus.value = 'start'
                successfullyIndexed.value = true
            }
            
        }

        const handleParseFromUrl = async(isActive) => {
        
            if (!urlForParsing.value ) {
                return
            }
            parsingUrl.value = true

            const response = await api.post(`/api/ai/parse-from-url`, {
                link: urlForParsing.value
            })
            if(response.error) {
                errorIndexed.value = true
                errorMessage.value = response.error
                parsingUrl.value = false

                return
            }

            trainTextRef.value = response.text
            parsingUrl.value = false
            isActive.value = false
            urlForParsing.value = null
        }

        return { 
            trainTextRef, 
            handleTrainButton, 
            trainingStatus, 
            accountId, 
            successfullyIndexed, 
            errorIndexed, 
            errorMessage, 
            MAX_TEXT_COUNT, 
            documentsStore,
            handleParseFromUrl,
            parsingUrl,
            urlForParsing
        }
    }
}
</script>