<template>
    <v-dialog max-width="800" persistent v-model="showModal">
        <template v-slot:activator="{ props: activatorProps }">
            <v-chip class="text-capitalize text-medium-emphasis cursor-pointer" size="small" variant="text" v-bind="activatorProps">{{ $t('consent.modal.btnTitle') }}</v-chip>
        </template>

        <template v-slot:default="{ isActive }">
            <v-card>
            <v-card-title class="ml-2 mt-2">
                <div class="d-flex justify-space-between">
                    <div class="exie-text-h6">{{ $t('consent.modal.title') }}</div>
                    <div><v-btn variant="text" class="cursor-pointer" @click="showModal = false">&#10006;</v-btn></div>
                </div>
            </v-card-title>
            <v-card-text>
                <p>{{ $t('consent.modal.description') }}</p>
                <v-list class="exie-text-body-2">
                    <v-list-item class="bg-brown-lighten-5 pa-3 my-3">
                        <v-row>
                            <v-col cols="10">
                                <p class="font-weight-black">{{ $t('consent.modal.essentialTitle') }}</p>
                                {{ $t('consent.modal.essentialDescription') }}
                            </v-col>
                            <v-col cols="2" class="float-right">
                                <v-switch
                                v-model="consentModel"
                                value="essential"
                                color="grey-darken-3"
                                disabled
                            />
                            </v-col>
                        </v-row>
                    </v-list-item>
                    <v-list-item class="bg-brown-lighten-5 pa-3 my-3">
                        <v-row>
                            <v-col cols="10">
                                <p class="font-weight-black">{{ $t('consent.modal.preferenceTitle') }}</p>
                                {{ $t('consent.modal.preferenceDescription') }}
                            </v-col>
                            <v-col cols="2">
                                <v-switch
                                v-model="consentModel"
                                value="preference"
                                color="grey-darken-3"
                            />
                            </v-col>
                        </v-row>
                    </v-list-item>
                    <v-list-item class="bg-brown-lighten-5 pa-3 my-3">
                        <v-row>
                            <v-col cols="10">
                                <p class="font-weight-black">{{ $t('consent.modal.advertisingTitle') }}</p>
                                {{ $t('consent.modal.advertisingDescription') }}
                            </v-col>
                            <v-col cols="2">
                                <v-switch
                                v-model="consentModel"
                                value="advertising"
                                color="grey-darken-3"
                            />
                            </v-col>
                        </v-row>
                    </v-list-item>
                    <v-list-item class="bg-brown-lighten-5 pa-3 my-3">
                        <v-row>
                            <v-col cols="10">
                                <p class="font-weight-black">{{ $t('consent.modal.analyticsTitle') }}</p>
                                {{ $t('consent.modal.analyticsDescription') }}
                            </v-col>
                            <v-col cols="2">
                                <v-switch
                                v-model="consentModel"
                                value="analytics"
                                color="grey-darken-3"
                            />
                            </v-col>
                        </v-row>
                    </v-list-item>
                </v-list>
            </v-card-text>

            
            <v-spacer></v-spacer>

            <div class="d-flex flex-wrap justify-center w-100 mb-5">
                <div class="ma-3">
                    <v-btn @click="savePreferences" color="grey-darken-3">{{ $t('consent.modal.btnSubmit') }}</v-btn>
                </div>
                <div class="ma-3">
                    <v-btn @click="rejectAll" color="grey-darken-3">{{ $t('consent.modal.btnReject') }}</v-btn>
                </div>
                <div class="ma-3">
                    <v-btn @click="acceptAll" color="grey-darken-3">{{ $t('consent.modal.btnAccept') }}</v-btn>
                </div>
            </div>
            
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
import { useCookies } from "vue3-cookies"
import { ref } from "vue"
export default {
    setup() {
        const showModal = ref(false)
        const { cookies } = useCookies()
        const cookieConsent = JSON.parse(cookies.get('consent'))
        const consentModel = ref(cookieConsent ? cookieConsent : ['essential'])


        const acceptAll = () => {
            consentModel.value = ['essential', 'preference', 'advertising', 'analytics']
            savePreferences()
        }

        const rejectAll = () => {
            consentModel.value = ['essential']
            savePreferences()
        }

        const savePreferences = () => {
            cookies.set('consent', JSON.stringify(consentModel.value), '180d')
            showModal.value = false
            window.location.reload()
        }

        return {acceptAll, rejectAll, savePreferences, consentModel, showModal}
    }
} 
</script>