<template>
    <v-container>
        <v-breadcrumbs class="text-medium-emphasis">
            <v-breadcrumbs-item :to="{name: 'accounts'}"> 
                {{ $t('accounts.title') }}
            </v-breadcrumbs-item>
            <v-breadcrumbs-divider />
            <v-breadcrumbs-item active-color="primary" active="true" title="Create New Account" :to="{name: 'account-create'}">
                {{ $t('accountCreate.title') }}
            </v-breadcrumbs-item>
        </v-breadcrumbs>

        <v-row class="mt-8">
            <v-col cols="12" md="5">
                <p class="exie-text-body-1">
                    <i18n-t keypath="accountCreate.description.text" tag="p">
                        <template v-slot:url>
                            <a class="exie-link" href="https://help.exie.app/Connecting-Exie-e14a310856f94bf88299810fb21891c2" target="_blank">{{ $t('accountCreate.description.url') }}</a>
                        </template>
                    </i18n-t>
                </p>
            </v-col>
            <v-col cols="12" md="7">
                <div class="w-100 h-100 d-flex flex-column justify-end align-end">
                    <v-btn class="mt-1" color="primary" @click="handleIgLogin">{{ $t('accountCreate.btnIgLogin') }}</v-btn>
                </div>
            </v-col>
        </v-row>

        <div class="mt-5">
            <v-sheet v-for="(page, index) in pages" :key="index"
                    class="border border-thin rounded-lg my-3"
            >
                <v-row class="ma-5">
                    <v-col cols="12" md="2" class="pa-0 d-flex align-center">
                        <v-avatar color="grey-darken-3" size="45" :image="page.instagram_business_account.profile_picture_url" />
                    </v-col>
                    <v-col cols="6" md="3" class="pa-0">
                        <p class="exie-text-body-1 mt-1">{{ page.instagram_business_account.name ?? page.name }}</p>
                        <p class="exie-text-caption text-medium-emphasis">{{ page.instagram_business_account.username }}</p>
                    </v-col>
                    <v-col cols="6" md="7" class="pa-0 d-flex align-center justify-end">
                        <v-btn v-if="!page.is_connected" :disabled="processing" color="primary" flat @click="createAccount(page.id, page.connected_instagram_account.id, page.access_token)">{{$t('accountCreate.btnConnect')}}</v-btn>
                        <v-btn v-else-if="page.is_connected && !page.has_access" color="grey-lighten-2 text-grey-darken-1 cursor-default" :ripple="false" flat>
                            {{$t('accountCreate.btnGoToAccount')}}
                            <v-tooltip
                                activator="parent"
                                location="top"
                            >{{$t('accountCreate.tooltipConnectedWithoutAccess')}}</v-tooltip>
                        </v-btn>
                        <v-btn v-else-if="page.is_connected && page.has_access" color="grey-lighten-4" flat @click="goToAccount(page.account_id)">
                            {{$t('accountCreate.btnGoToAccount')}}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-sheet>
        </div>
    </v-container>
</template>

<script>
import { ref } from 'vue'
import { useAppStateStore } from '@/stores/AppStateStore'
import { useRouter, useRoute } from 'vue-router'
import api from '@/utils/api'

export default {
    components: {},
    setup() {
        const pages = ref([])
        const appStateStore = useAppStateStore()
        const connectedIgPageIds = {}
        const router = useRouter()
        const route = useRoute()
        const loading = ref(false)
        const processing = ref(false)
        
        const getFbPages = async (manual) => {
            loading.value = true
            await appStateStore.reloadAccounts()
            appStateStore.data.accounts.forEach((account) => {
                connectedIgPageIds[account.instagram_id] = account.id
            })

            const pagesData = await api.get(`/api/fb/pages?instagram=1&manual=${manual ? 1 : 0}`)
            pagesData.forEach((pageData) => {
                if(connectedIgPageIds[pageData.connected_instagram_account.id]) {
                    pageData.has_access = true
                    pageData.account_id = connectedIgPageIds[pageData.connected_instagram_account.id]
                }
            })

            pagesData.sort((a, b) => {
                return a.is_connected ? 1 : -1
            })
            
            pages.value = pagesData
            loading.value = false
        }
        //@TODO uncomment for production
        getFbPages(false)

        const createAccount = async(fbPageId, igId, pageToken) => {
            processing.value = true
            const state = {}
            if(route.query.shopify) {
                state.shopify = route.query.shopify
            }

            const accountData = await api.post('/api/account/create-from-ig', {
                fb_page_id: fbPageId,
                ig_page_id: igId,
                page_token: pageToken,
                state: state
            })

            processing.value = false

            if(accountData.error) {
                console.log('something went wrong')
                return
            }

            appStateStore.addAccount(accountData)
            appStateStore.updateCurrentAccount(accountData)

            if(route.query.shopify) {
                router.push({
                    name: 'shopify',
                    query: {
                        shop: route.query.shopify
                    }
                })
            } else {
                router.push({
                    name: 'dashboard',
                    params: {
                        account_id: accountData.id
                    }
                })
            }

        }

        const handleIgLogin = async () => {
            const params = {}
            if(route.query.shopify) {
                params.shopify = route.query.shopify
            }
            const response = await api.post(`/api/auth/fb/ig-sign-in`, params)
            if(response.redirect) {
                window.location.href = response.redirect
            }
        }

        const goToAccount = (accountId) => {
            router.push({
                name: 'dashboard',
                params: {
                    account_id: accountId
                }
            })
        }

        return {pages, getFbPages, createAccount, goToAccount, appStateStore, loading, handleIgLogin, processing}
    }
}
</script>