<template>
    <div v-if="initialized">
        <v-card rounded="xl" variant="flat" color="grey-lighten-4" min-height="250px">
            <p class="mt-5 mx-6 exie-text-subtitle-1 font-weight-bold">
                <span>{{$t('accountSettings.billing.currentPlanTitle')}}</span>
                <v-chip rounded="lg" variant="flat" class="float-right" :color="getProStatusLabelColor(currentAccount.pro_status)">{{ getProStatusLabel(currentAccount.pro_status) }}</v-chip>
            </p>
            <p class="mt-5 ml-6">
                <v-list class="bg-grey-lighten-4 ma-0 pa-0" color="grey-lighten-4" variant="text">
                    <v-list-item :title="owner.user.name" :subtitle="$t('accountSettings.team.ownerTitle')" class="my-1 bg-grey-lighten-4 ma-0 pa-0" variant="text">
                        <template v-slot:prepend>
                            <v-avatar color="grey-lighten-4" size="48" :image="owner.user.picture"></v-avatar>
                        </template>
                    </v-list-item>
                </v-list>
            </p>
            <div class="mx-6 mt-5 exie-text-subtitle-2">
                <p>
                    <span>{{$t('accountSettings.billing.subscriptionMessagesTitle')}}</span>
                    <span class="float-right">{{ subscriptionMessagesAvailable }}/{{ messagesLimit }}</span>
                </p>
                <p class="mt-2">
                    <span>{{$t('accountSettings.billing.extraMessagesTitle')}}</span>
                    <span class="float-right">{{ extraMessagesAvailable }}</span>
                </p>
                <div v-if="[1, 2].includes(currentAccount.pro_status) && subscription">
                    <div v-if="!subscription.cancel_at_period_end">
                        <p class="mt-2">
                            <span>{{$t('accountSettings.billing.subscriptionCostTitle')}}</span>
                            <span class="float-right">
                                ${{ Math.floor(subscription.plan.amount / 100)}} {{$t(`accountSettings.billing.period_${subscription.plan.interval}`)}}
                            </span>
                        </p>
                        <p class="mt-2">
                            <span>{{$t('accountSettings.billing.nextPaymentTitle')}}</span>
                            <span class="float-right">
                                {{ getFullDate(subscription.current_period_end) }}
                            </span>
                        </p>
                        
                    </div>
                    <div v-else>
                        <p class="mt-2">
                            <span class="exie-text-body-2 text-red">{{$t('accountSettings.billing.subscriptionUntilTitle')}}</span>
                            <span class="float-right">
                                {{ getFullDate(subscription.current_period_end) }}
                            </span>
                        </p>
                        <p class="mt-2">
                            <span class="text-pre-line">{{$t('accountSettings.billing.subscriptionUntilDescription')}}</span>
                        </p>
                    </div>
                    <p class="mt-2">
                        <v-chip variant="text" color="primary" class="mx-0 px-0 cursor-pointer">
                            {{$t('accountSettings.billing.billingInformationTitle')}}
                            <InvoicesModal />
                        </v-chip>
                    </p>
                </div>
                <div v-else-if="[1, 2].includes(currentAccount.pro_status) && subscriptionManual">
                    <p class="mt-2">
                        <span>{{$t('accountSettings.billing.nextManualUpdateTitle')}}</span>
                        <span class="float-right">
                            {{ getFullDateFromString(subscriptionManual.next_billing_date) }}
                        </span>
                    </p>
                </div>
                <div v-else-if="currentAccount.pro_status == 8">
                    <p class="mt-3">
                        <span>{{ $t('accountSettings.billing.vipDescription') }} support@exie.app</span>
                    </p>
                </div>
            </div>
            
            <p class="mt-10 mb-5 mx-6">
                <v-btn v-if="!currentAccount.is_pro" variant="flat" color="primary" size="x-large" block>
                    {{$t('accountSettings.billing.btnUpgradeToPRO')}}
                    <UpgradeToProModal  />
                </v-btn>
                <div v-else-if="[1, 2].includes(currentAccount.pro_status) && subscription">
                    <v-btn v-if="!subscription.cancel_at_period_end" variant="text" block>
                        {{$t('accountSettings.billing.btnCancelSubscription')}}
                        <CancelSubscriptionModal :pro_status="currentAccount.pro_status" />
                    </v-btn>
                    <v-btn v-else variant="flat" color="primary" size="x-large" block :loading="loadingRenew" @click="handleRenewSubscription">
                        {{$t('accountSettings.billing.btnRenewSubscription')}}
                    </v-btn>
                </div>
                
            </p>
        </v-card>
    </div>
</template>

<script>
import { ref } from 'vue'
import api from '@/utils/api'
import { useRoute } from 'vue-router'
import { getProStatusLabel, getProStatusLabelColor } from '@/utils/AccountUtils'
import { useAccountStatsStore } from '@/stores/AccountStatsStore'
import { useAccountMembersStore } from '@/stores/AccountMembersStore'
import { useAppStateStore } from '@/stores/AppStateStore'
import { storeToRefs } from 'pinia'
import { getFullDate, getFullDateFromString } from '@/utils/dataFormatter'
import { useAccountSubscriptionStore } from '@/stores/AccountSubscriptionStore'
import UpgradeToProModal from '@/components/accountSettings/billing/UpgradeToProModal.vue'
import CancelSubscriptionModal from '@/components/accountSettings/billing/CancelSubscriptionModal.vue'
import InvoicesModal from '@/components/accountSettings/billing/InvoicesModal.vue'

export default {
    components: {UpgradeToProModal, CancelSubscriptionModal, InvoicesModal},
    setup() {
        const initialized = ref(false)
        const route = useRoute()
        const accountId = route.params.account_id

        const appStateStore = useAppStateStore()
        const {currentAccount} = storeToRefs(appStateStore)

        const accountStatsStore = useAccountStatsStore()
        const { messagesLimit, subscriptionMessagesAvailable, extraMessagesAvailable } = storeToRefs(accountStatsStore)

        const accountMembersStore = useAccountMembersStore()
        const { owner } = storeToRefs(accountMembersStore)

        const accountSubscriptionStore = useAccountSubscriptionStore()
        accountSubscriptionStore.init(accountId)
        const { subscription, subscriptionManual } = storeToRefs(accountSubscriptionStore)

        const initialize = async() => {
            await accountSubscriptionStore.init(accountId)
            await accountStatsStore.init(accountId)
            await accountMembersStore.init(accountId)
            
            initialized.value = true
        }

        const loadingRenew = ref(false)

        const handleRenewSubscription = async () => {
            loadingRenew.value = true
            const response = await api.put(`/api/billing/${accountId}/subscription`)
            loadingRenew.value = false
            if(response.error) {
                console.log(response.error)
                return
            }

            accountSubscriptionStore.update(accountId, response.subscription)
        }        

        initialize()

        return {
            initialized,
            subscription,
            subscriptionMessagesAvailable,
            extraMessagesAvailable,
            messagesLimit,
            owner,
            currentAccount,
            getProStatusLabel,
            getProStatusLabelColor,
            getFullDate,
            getFullDateFromString,
            loadingRenew,
            handleRenewSubscription,
            subscriptionManual
        }
    }
}
</script>