import { defineStore } from 'pinia'
import api from '@/utils/api'


export const useAccountMembersStore = defineStore('accountMembersStore', { 
    state: () => ({
        members: []
    }),
    getters: {
        owner: (state) => {
            return state.members.find((member) => member.is_owner == true)
        }
    },
    actions: {
        async init(accountId) {
            const response = await api.get(`/api/team/${accountId}/members`)
            this.members = response
        },
        addMember(member) {
            const memberExists = this.members.find((mem) => mem.user_id == member.user_id)
            if(!memberExists) {
                this.members.push(member)
            }
        },
        deleteMember(member) {
            this.members = this.members.filter((mem) => mem.user_id != member.user_id)
        }
    }
})