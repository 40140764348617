<template>
    <v-card flat color="grey-lighten-4" rounded="xl" height="100%" min-height="250px"class="d-flex flex-column">
        <p class="mt-3 mx-6 exie-text-subtitle-1 font-weight-bold align-self-start">
            {{$t('dashboard.widget.messages.title')}}
        </p>
        <v-card-text class="d-flex flex-column justify-center align-center">
            <span class="exie-text-h3 text-no-wrap">{{ messagesAvailable }}</span>
        </v-card-text>
        <p class="mt-3"></p>
    </v-card>
</template>

<script>
import { useAccountStatsStore } from '@/stores/AccountStatsStore'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
export default {
    setup() {
        const route = useRoute()
        const accountId = ref(route.params.account_id)
        const accountStatsStore = useAccountStatsStore()
        accountStatsStore.init(accountId.value)
        
        const {messagesLimit, subscriptionMessagesAvailable, extraMessagesAvailable, messagesAvailable} = storeToRefs(accountStatsStore)

        return { messagesLimit, subscriptionMessagesAvailable, extraMessagesAvailable, messagesAvailable }
    }
}
</script>

<style scoped>
.mh-200 {
    min-height: 200px;
}
</style>