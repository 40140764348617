<template>
    <Clarity :consent="isAnalyticsAvailable" />
    <MixPanel v-if="isAnalyticsAvailable" />
</template>

<script>
import { useCookies } from "vue3-cookies"
import MixPanel from './MixPanel.vue'
import Clarity from './Clarity.vue'
export default {
    components: {MixPanel, Clarity},
    setup() {
        const { cookies } = useCookies()
        const cookieConsent = JSON.parse(cookies.get('consent') ?? '[]')

        const isAnalyticsAvailable = cookieConsent.includes('analytics')

        return {isAnalyticsAvailable}
    }
}
</script>