const PRO_STATUS_TRIAL = 0
const PRO_STATUS_PRO = 1
const PRO_STATUS_LIGHT = 2
const PRO_STATUS_EXPIRED = -1
const PRO_STATUS_VIP = 8


const getProStatusLabel = (proStatus) => {
    switch (proStatus) {
        case PRO_STATUS_TRIAL:
            return 'TRIAL'
        case PRO_STATUS_PRO:
            return 'PRO'
        case PRO_STATUS_LIGHT:
            return 'LITE'
        case PRO_STATUS_EXPIRED:
            return 'EXPIRED'
        case PRO_STATUS_VIP:
            return 'VIP'
    }
}
const getProStatusLabelColor = (proStatus) => {
    switch (proStatus) {
        case PRO_STATUS_TRIAL:
            return 'black'
        case PRO_STATUS_PRO:
            return 'primary'
        case PRO_STATUS_LIGHT:
            return 'green-accent-2'
        case PRO_STATUS_EXPIRED:
            return 'red'
        case PRO_STATUS_VIP:
            return 'amber'
    }
}

export { getProStatusLabel, getProStatusLabelColor}