<template>
    <v-sheet v-if="accountId">
        <div>
            <v-alert type="error" rounded="xl" v-if="currentAccount?.status === 90">
                <i18n-t keypath="navbar.notify.tokenExpired.text" tag="p">
                    <template v-slot:url>
                        <router-link class="exie-link" :to="{name: 'account_settings', params: {account_id: accountId}}">{{ $t('navbar.notify.tokenExpired.url') }}</router-link>
                    </template>
                </i18n-t>
            </v-alert>
        </div>
        <div>
            <v-alert type="info" rounded="xl" closable v-if="currentAccount?.is_bot_active == false" >
                <i18n-t keypath="navbar.notify.botIsNotActive.text" tag="p">
                    <template v-slot:url>
                        <router-link class="exie-link" :to="{name: 'account_settings', params: {account_id: accountId}}">{{ $t('navbar.notify.botIsNotActive.url') }}</router-link>
                    </template>
                </i18n-t>
            </v-alert>
        </div>
        <div>
            <v-alert type="warning" rounded="xl" closable v-if="currentAccount?.has_available_messages == false" :text="$t('navbar.notify.noMessages')" ></v-alert>
        </div>
    </v-sheet>
</template>

<script>
import { useAppStateStore } from '@/stores/AppStateStore'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
export default {
    setup() {
        const route = useRoute()
        const router = useRouter()
        const accountId = ref(route.params.account_id)

        router.afterEach((to, from) => {
            accountId.value = to.params.account_id
        })

        const appStateStore = useAppStateStore()
        const {currentAccount} = storeToRefs(appStateStore)

        return {currentAccount, accountId}
    }
}
</script>