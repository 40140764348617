<template>
    <v-bottom-sheet v-model="showConsentBanner" persistent>
        <v-sheet class="h-20dv" color="grey-lighten-2">
            <div class="mx-10 my-5">
                <p class="exie-text-subtitle-1 mb-3 font-weight-bold">{{ $t('consent.banner.title') }}</p>
                <div class="exie-text-body-2">
                    {{ $t('consent.banner.description') }}
                </div>
                <v-row class="mt-1">
                    <v-col cols="auto">
                        <v-switch
                            v-model="consentModel"
                            :label="$t('consent.banner.lblEssential')"
                            value="essential"
                            color="primary"
                            disabled
                        />
                    </v-col>
                    <v-col cols="auto">
                        <v-switch
                            v-model="consentModel"
                            :label="$t('consent.banner.lblPreference')"
                            value="preference"
                            color="primary"
                        />
                    </v-col>
                    <v-col cols="auto">
                        <v-switch
                            v-model="consentModel"
                            :label="$t('consent.banner.lblAdvertising')"
                            value="advertising"
                            color="primary"
                        />
                    </v-col>
                    <v-col cols="auto">
                        <v-switch
                            v-model="consentModel"
                            :label="$t('consent.banner.lblAnalytics')"
                            value="analytics"
                            color="primary"
                        />
                    </v-col>
                </v-row>
            </div>
            <div class="d-flex flex-wrap justify-end w-100 mb-3 pr-5">
                    <div class="ma-3">
                        <v-btn @click="savePreferences" color="primary">{{ $t('consent.banner.btnSubmit') }}</v-btn>
                    </div>
                    <div class="ma-3">
                        <v-btn @click="rejectAll" color="primary">{{ $t('consent.banner.btnReject') }}</v-btn>
                    </div>
                    <div class="ma-3">
                        <v-btn @click="acceptAll" color="primary">{{ $t('consent.banner.btnAccept') }}</v-btn>
                    </div>
                </div>
        </v-sheet>
    </v-bottom-sheet>
</template>

<script>
import { useCookies } from "vue3-cookies"
import { ref } from "vue"
export default {
    setup() {
        const showConsentBanner = ref(false)
        const { cookies } = useCookies()
        const cookieConsent = JSON.parse(cookies.get('consent'))
        const consentModel = ref(cookieConsent ? cookieConsent : ['essential'])


        const acceptAll = () => {
            consentModel.value = ['essential', 'preference', 'advertising', 'analytics']
            savePreferences()
        }

        const rejectAll = () => {
            consentModel.value = ['essential']
            savePreferences()
        }

        const savePreferences = () => {
            cookies.set('consent', JSON.stringify(consentModel.value), '180d')
            showConsentBanner.value = false
            window.location.reload()
        }

        showConsentBanner.value = cookieConsent ? false : true

        return {acceptAll, rejectAll, savePreferences, consentModel, showConsentBanner}
    }
} 
</script>

<style scoped>
.h-20dvh {
    min-height: 20dvh;
}
</style>