import { defineStore } from 'pinia'
import api from '@/utils/api'

export const useAppStateStore = defineStore('appStateStore', {
    state: () => ({
        loaded: false,
        loading: false,
        data: {}
    }),
    getters: {
        allAccountsExcludingCurrent: (state) => {
            const accounts = state.data.accounts ?? []
            return accounts.filter((account) => {
                return account.id !== state.data?.currentAccount.id
            })
        },
        currentAccount: (state) => {
            return state.data?.currentAccount ?? null
        },
        accountUserRoles: (state) => {
            return state.data?.accountUserRoles ?? null
        },
        isCurrentAccountOwner: (state) => {
            if (!state.currentAccountId) {
                return false
            }
            return state?.accountUserRoles[state.currentAccountId]?.is_owner ?? false
        },
        accounts: (state) => {
            return state.data?.accounts ?? []
        },
        user: (state) => {
            return state.data.user ?? null
        },
        userId: (state) => {
            return state.data?.user?.id ?? null
        },
        currentAccountId: (state) => {
            return state.data?.currentAccount?.id ?? null
        },
        currentOnboardingStatus: (state) => {
            return state.currentAccount?.onboarding_status ?? null
        },
        currentOnboardingStep: (state) => {
            return state.currentAccount?.onboarding_step ?? 0
        }
    },
    actions: {
        async loadState() {
            this.loading  = true
            const stateData = await api.get('/api/init/state')

            this.data = stateData
            this.loading = false
            this.loaded = true
        },
        async reloadState() {
            const stateData = await api.get('/api/init/state')
            this.data = stateData
        },
        async reloadAccounts() {
            const accounts = await api.get('/api/account/list')
            this.data.accounts = accounts
        },
        updateAccounts(accounts) {
            this.data.accounts = accounts
        },
        addAccountUserRole(accountUserRole) {
            if(this.userId == accountUserRole.user_id) {
                this.data.accountUserRoles = this.data.accountUserRoles ?? {}
                this.data.accountUserRoles[accountUserRole.account_id] = accountUserRole
            }
        },
        deleteAccountUserRole(accountUserRole) {
            if(this.userId == accountUserRole.user_id) {
                delete this.data.accountUserRoles[accountUserRole.account_id]
                this.deleteAccount({id: accountUserRole.account_id})
            }
        },
        updateUser(user) {
            this.data.user = user
        },
        addAccount(account) {
            this.data.accounts.push(account)
        },
        updateAccount(account) {
            if (this.currentAccountId == account.id) {
                this.updateCurrentAccount(account, true)
            } else {
                this.data.accounts.forEach((acc) => {
                    if (acc.id === account.id) {
                        acc = account
                        success = true
                    }
                })
            }
        },
        deleteAccount(account) {
            if(this.data?.accounts) {
                this.data.accounts = this.data.accounts.filter((acc) => acc.id != account.id)
            }

            if(this.currentAccountId == account.id) {
                this.updateCurrentAccount(this.data?.accounts[0] ?? null)
            }
        },
        updateAccountUserRole(accountUserRole) {
            this.data.accountUserRoles[accountUserRole.account_id] = accountUserRole
        },
        updateCurrentAccount(currentAccount, updateList = false) {
            this.data.currentAccount = currentAccount
            if (updateList) {
                this.data.accounts.forEach((acc) => {
                    if (acc.id === currentAccount.id) {
                        acc = currentAccount
                    }
                })
            }
        }
    }
})