<template>
    <v-sheet>
        <p class="exie-text-subtitle-1 font-weight-bold mb-2">{{$t('accountSettings.general.bot.title')}}</p>
        <v-row>
            <v-col cols="12" sm="6" md="5">
                <p v-if="currentAccount?.pro_status == -1" class="exie-text-body-1 text-pre-line">{{$t('accountSettings.general.bot.isActiveAccountExpired')}}</p>
                <p v-else-if="isBotActive" class="exie-text-body-1 text-pre-line">{{$t('accountSettings.general.bot.isActiveDescription')}}</p>
                <p v-else class="exie-text-body-1 text-pre-line">{{$t('accountSettings.general.bot.isInactiveDescription')}}</p>
            </v-col>
            <v-col cols="12" sm="6" md="7">
                <v-switch 
                    inset
                    :readonly="isBotActiveLoading" 
                    :loading="isBotActiveLoading" 
                    hide-details
                    color="primary" 
                    v-model="isBotActive" 
                    @update:modelValue="handleUpdateIsBotActive"
                    :disabled="currentAccount?.pro_status == -1"
                />
            </v-col>
        </v-row>

        <v-divider class="my-8" />

        <p class="exie-text-subtitle-1 font-weight-bold mb-2">{{$t('accountSettings.general.bot.contactOperatorTitle')}}</p>
        <v-row>
            <v-col cols="12" sm="6" md="5">
                <p class="exie-text-body-1 text-pre-line pr-3">{{$t('accountSettings.general.bot.contactOperatorDescription')}}</p>
            </v-col>
            <v-col cols="12" sm="6" md="7">
                <v-text-field 
                    v-model="callOperatorText" 
                    rounded="lg" 
                    :disabled="saveCallOperatorTextBlocked" 
                    single-line 
                    flat 
                    hide-details 
                    variant="solo-filled" 
                    :label="$t('accountSettings.general.bot.callOperatorTextLabel')" 
                    maxlength="20" 
                    counter
                    :placeholder="$t('accountSettings.general.bot.callOperatorTextPlaceholder')"
                />
                <v-textarea 
                    no-resize 
                    rows="3" 
                    class="mt-3" 
                    v-model="callOperatorResponse" 
                    rounded="lg" 
                    :disabled="saveCallOperatorTextBlocked" 
                    hide-details 
                    single-line 
                    flat 
                    variant="solo-filled" 
                    :label="$t('accountSettings.general.bot.callOperatorResponseLabel')" 
                    maxlength="200" 
                    counter
                    :placeholder="$t('accountSettings.general.bot.callOperatorResponsePlaceholder')"
                ></v-textarea>
                <v-btn class="mt-3" :disabled="saveCallOperatorTextBlocked" color="primary" @click="handleSaveCallOperatorText">{{$t('accountSettings.general.bot.btnSave')}}</v-btn>
            </v-col>
        </v-row>
    </v-sheet>
    <v-snackbar location="top right" color="error" v-model="error" :timeout="3000">
        {{ errorMessage }}
    </v-snackbar>
    <v-snackbar location="top right" color="success" v-model="success" :timeout="3000">
        {{ successMessage }}
    </v-snackbar>
</template>

<script>
import { useAppStateStore } from '@/stores/AppStateStore'
import {useRoute} from 'vue-router'
import { ref } from 'vue'
import { useI18n } from "vue-i18n"
import api from '@/utils/api'
import PaywallModal from '@/components/accountSettings/paywall/PaywallModal.vue'
import { storeToRefs } from 'pinia'
export default {
    components: {PaywallModal},
    setup() {
        const {t} = useI18n()
        const appStateStore = useAppStateStore()
        const {currentAccount} = storeToRefs(appStateStore)
        const isBotActive = ref(appStateStore.currentAccount.is_bot_active)

        const route = useRoute()
        const accountId = route.params.account_id

        const showPaywall = ref(false)

        const saveCallOperatorTextBlocked = ref(false)
        const callOperatorText = ref(appStateStore.currentAccount.call_operator_text)
        const callOperatorResponse = ref(appStateStore.currentAccount.call_operator_response)

        const error = ref(false)
        const errorMessage = ref(null)
        const success = ref(false)
        const successMessage = ref(null)

        const isBotActiveLoading = ref(false)

        const closePaywallModal = () => {
            showPaywall.value = false
        }

        const handleUpdateIsBotActive = async (value) => {
            isBotActiveLoading.value = true

            const responseData = await api.post(`/api/account/change-bot-active/${accountId}`, {is_bot_active: value})

            if(responseData.error) {
                error.value = true
                errorMessage.value = responseData.error
                isBotActive.value = !value
                isBotActiveLoading.value = false

                return
            }

            appStateStore.updateCurrentAccount(responseData, true)
            success.value = true
            successMessage.value = 'Your bot was successfully ' + (value ? 'activated' : 'deactivated')
            successMessage.value = value ?
                 t('accountSettings.general.bot.notify.successActivate') : 
                 t('accountSettings.general.bot.notify.successDeactivate')

            isBotActiveLoading.value = false

        }

        const handleSaveCallOperatorText = async () => {
            if(!callOperatorText.value || !callOperatorResponse.value) {
                error.value = true
                errorMessage.value = t('accountSettings.general.bot.notify.callOperatorEmpty')
                return 
            }

            saveCallOperatorTextBlocked.value = true

            const responseData = await api.post(`/api/account/call-operator-text/${accountId}`, {
                call_operator_text: callOperatorText.value, 
                call_operator_response: callOperatorResponse.value
            })

            if(responseData.error) {
                error.value = true
                errorMessage.value = responseData.error

                return
            }

            appStateStore.updateCurrentAccount(responseData, true)
            success.value = true
            successMessage.value = t('accountSettings.general.bot.notify.callOperatorUpdated')


            saveCallOperatorTextBlocked.value = false
        }

        return { 
            isBotActive, 
            isBotActiveLoading, 
            handleUpdateIsBotActive, 
            error, 
            errorMessage, 
            success, 
            successMessage,
            saveCallOperatorTextBlocked,
            handleSaveCallOperatorText,
            callOperatorText,
            callOperatorResponse,
            showPaywall,
            closePaywallModal,
            currentAccount
        }
    }
}
</script>