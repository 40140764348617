<template>
    <v-container class="d-flex flex-column justify-center align-center">
        <div class="mt-10 text-center" :style="{maxWidth: '660px'}">
            <p class="exie-text-h4">{{ $t('appsumo.activated.title') }}</p>
        </div>
        <div class="mt-2 text-center" :style="{maxWidth: '430px'}">
            <p class="exie-text-body-1 mt-5 text-pre-line description-block">
                <i18n-t keypath="appsumo.activated.description" tag="p">
                    <template v-slot:email>
                        <span class="text-primary">support@exie.app</span>
                    </template>
                </i18n-t>
            </p>
        </div>
        <div class="mt-2 text-center">
            <v-btn color="primary" class="mt-5" :to="{name: 'default-account-entry'}">{{ $t('appsumo.activated.btnGoToDashboard') }}</v-btn>
        </div>
    </v-container>
</template>
