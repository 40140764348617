<template>
    <v-sheet>
        <p class="exie-text-subtitle-1 font-weight-bold mb-2">{{ $t('accountSettings.integrations.googleSheets.title')}}</p>   
        <v-row v-if="!integrationLoaded" class="pa-10">
            <v-col cols="12" sm="6" md="5">
                    <v-skeleton-loader width="100%" type="paragraph"></v-skeleton-loader>
                </v-col>
                <v-col cols="12" sm="6" md="7">
                    <v-skeleton-loader width="100%" type="paragraph"></v-skeleton-loader>
                </v-col>
        </v-row>
        <v-row v-else-if="!integration">
            <v-col cols="12" sm="6" md="5">
                <p class="exie-text-body-1 text-pre-line pr-3">{{ $t('accountSettings.integrations.googleSheets.connectDescription') }}</p>
            </v-col>
            <v-col cols="12" sm="6" md="7">
                <v-btn color="primary" @click="handleConnectGoogleSheets">{{ $t('accountSettings.integrations.googleSheets.btnConnect') }}</v-btn>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12" sm="6" md="5">
                <p class="exie-text-body-1 text-pre-line pr-3">{{ $t('accountSettings.integrations.googleSheets.chooseSpreadsheetDescription') }}</p>
            </v-col>
            <v-col cols="12" sm="6" md="7">
                <div class="exie-text-body-1 text-pre-line pr-3 w-100">
                    <v-text-field
                        v-if="!goodsSpreadsheet"
                        v-model="spreadSheetLink"
                        rounded="lg"
                        variant="solo-filled"
                        label="Google Spreadsheet link"
                        flat
                    />
                    <v-text-field
                        v-else
                        rounded="lg"
                        variant="solo-filled"
                        label="Google Spreadsheet"
                        flat
                        :model-value="goodsSpreadsheet.title"
                        readonly
                    >
                        <template v-slot:append-inner>
                            <v-btn @click="handleRemoveSpreadsheet" :icon="mdiClose" rounded="xl" density="compact" size="small"></v-btn>
                        </template>
                    </v-text-field>
                </div>
                <p class="mt-1 exie-text-caption text-red" v-if="integrationStatus == -1">{{ $t('accountSettings.integrations.googleSheets.connectedIntegrationError') }}</p>
                <p class="mt-3">
                    <v-btn color="primary" :disabled="saving || integrationStatus == -1" @click="handleSaveSettings">{{ $t('accountSettings.integrations.googleSheets.btnSave') }}</v-btn>
                    <v-btn class="float-right" @click="handleOpenDisconnectModal">{{ $t('accountSettings.integrations.googleSheets.btnDisconnect') }}</v-btn>
                </p>
            </v-col>
        </v-row>
    </v-sheet>
    <!-- Disconnect integration modal -->
    <v-dialog
      v-model="showDisconnectModal"
      width="516"
    >
      <v-card
        max-width="516"
        rounded="lg"
        class="px-2"
      >
        <p class="mt-2 mr-2 text-right">
            <v-btn size="x-small" color="grey-lighten-4" flat :icon="mdiClose" @click="handleCloseDisconnectModal"></v-btn>
        </p>
        <p class="px-5 text-center exie-text-h5">{{$t('accountSettings.integrations.googleSheets.disconnectTitle')}}</p>
        <div class="pa-5 my-8">
            <p class="text-center exie-text-body-1">{{$t('accountSettings.integrations.googleSheets.disconnectDescription')}}</p>
        </div>
        <p class="d-flex justify-space-between pa-5">
            <v-btn variant="flat" color="grey-lighten-4" @click="handleCloseDisconnectModal">{{$t('accountSettings.integrations.googleSheets.btnDisconnectClose')}}</v-btn>
            <v-btn variant="text" color="red" @click="handleDisconnect">{{$t('accountSettings.integrations.googleSheets.btnDisconnectConfirm')}}</v-btn>
        </p>
      </v-card>
    </v-dialog>
    <v-snackbar location="top right" color="error" v-model="error" :timeout="3000">
        {{ errorMessage }}
    </v-snackbar>
    <v-snackbar location="top right" color="success" v-model="success" :timeout="3000">
        {{ successMessage}}
    </v-snackbar>
</template>

<script>
import api from '@/utils/api'
import {useRoute} from 'vue-router'
import {ref} from 'vue'
import { useI18n } from "vue-i18n"
import { mdiClose} from '@mdi/js'
export default {
    setup() {
        const {t} = useI18n()
        const route = useRoute()
        const accountId = route.params.account_id

        const saving = ref(false)

        const showDisconnectModal = ref(false)

        const spreadSheetLink = ref(null)

        const error = ref(false)
        const errorMessage = ref(null)

        const success = ref(false)
        const successMessage = ref(null)

        const integration = ref(null)
        const integrationLoaded = ref(false)

        const integrationStatus = ref(null)

        const goodsSpreadsheet = ref(null)

        const handleConnectGoogleSheets = async () => {
            const response = await api.post(`/api/integrations/google/${accountId}/login`)

            window.location.href = response.redirect
        }

        const getIntegration = async () => {
            const response = await api.get(`/api/integrations/google/${accountId}/integration`)
            if(response.integration) {
                integration.value = response.integration
                goodsSpreadsheet.value = response.goods_spreadsheet
                spreadSheetLink.value = response.goods_spreadsheet?.link
                integrationStatus.value = response.status
            }
            integrationLoaded.value = true
        }

        const handleSaveSettings = async () => {
            saving.value = true
            const response = await api.post(`/api/integrations/google/${accountId}/settings`, {
                spreadsheet_link: spreadSheetLink.value
            })
            saving.value = false
            console.log(response)
            if (response.error) {
                error.value = true
                errorMessage.value = response.error == 'invalid spreadsheet link' ?
                    t('accountSettings.integrations.googleSheets.errorInvalidSpreadsheetLink') :
                    t('accountSettings.integrations.googleSheets.notifyError')
                return 
            }

            integration.value = response.integration
            goodsSpreadsheet.value = response.goods_spreadsheet
            spreadSheetLink.value = response.goods_spreadsheet?.link

            successMessage.value = goodsSpreadsheet.value ? 
                t('accountSettings.integrations.googleSheets.notifyConnected') :
                t('accountSettings.integrations.googleSheets.notifyDisconnected')

            success.value = true
        }

        const handleRemoveSpreadsheet = () => {
            spreadSheetLink.value = null
            goodsSpreadsheet.value = null
        }

        const handleCloseDisconnectModal = () => {
            showDisconnectModal.value = false
        }

        const handleOpenDisconnectModal = () => {
            showDisconnectModal.value = true
        }

        const handleDisconnect = async () => {
            const response = await api.post(`/api/integrations/google/${accountId}/disconnect`)
            handleCloseDisconnectModal()
            if (response.error) {
                error.value = true
                return
            }

            integration.value = null
            goodsSpreadsheet.value = null
            spreadSheetLink.value = null
        }

        getIntegration()

        return {
            handleConnectGoogleSheets, 
            integration, 
            goodsSpreadsheet, 
            error, 
            errorMessage, 
            handleSaveSettings, 
            integrationLoaded, 
            success,
            successMessage,
            mdiClose,
            handleCloseDisconnectModal,
            handleOpenDisconnectModal,
            handleDisconnect,
            showDisconnectModal,
            spreadSheetLink,
            handleRemoveSpreadsheet,
            saving,
            integrationStatus
        }
    }
}
</script>