<template>
    <v-container>
        <h4 class="mb-3 exie-text-h4">{{$t('dashboard.title')}}</h4>
        <v-sheet class="mt-10" width="100%">
            <v-row>
                <v-col cols="12" md="4">
                    <AccountInfo />
                </v-col>
                <v-col cols="12" md="4" >
                    <ContactsStats :contactsCount="contactsCount" />
                </v-col>
                <v-col cols="12" md="4">
                    <MessagesStats />
                </v-col>
            </v-row>
        </v-sheet>
    </v-container>

    <v-snackbar location="top right" color="success" v-model="successMessage" :timeout="5000">
        {{ successMessage}}
    </v-snackbar>
    
</template>

<script>
import { useAppStateStore } from '@/stores/AppStateStore'
import { useAccountStatsStore} from '@/stores/AccountStatsStore'
import AccountInfo from '@/components/dashboard/AccountInfo.vue'
import ContactsStats from '@/components/dashboard/ContactsStats.vue'
import MessagesStats from '@/components/dashboard/MessagesStats.vue'

import { useRoute } from 'vue-router'
import { ref, watch } from 'vue'
import api from '@/utils/api'
import { storeToRefs } from 'pinia'

export default {
    components: { AccountInfo, ContactsStats, MessagesStats },
    setup() {
        const successMessage = ref(null)
        const appStateStore = useAppStateStore()
        const accountStatsStore = useAccountStatsStore()
        const route = useRoute()
        const accountId = ref(route.params.account_id)

        if(route.query?.appsumo == 'activated') {
            successMessage.value = 'Your AppSumo license has been successfully activated!'
        }

        const {contactsCount, messagesSent, messagesLimit} = storeToRefs(accountStatsStore)
        
        const getAccountStats = async (accId) => {
            accountStatsStore.init(accId)
        }

        watch(route, (to) => {
            accountId.value = to.params.account_id
            getAccountStats(accountId.value)
        })

        getAccountStats(accountId.value)

        return { appStateStore, accountId, contactsCount, messagesLimit, messagesSent, successMessage }
    }
}
</script>