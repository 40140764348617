<template>
    <v-dialog activator="parent" max-width="800" persistent>
        <template v-slot:default="{ isActive }">
            <v-card
                rounded="lg"
                flat
                max-width="800"
            >
                <p class="mt-2 mr-2 text-right">
                    <v-btn size="x-small" variant="tonal" :icon="mdiClose" @click="isActive.value = false"></v-btn>
                </p>
                <div class="pt-4 px-8">
                    <p class="text-center exie-text-h5 mb-5">{{$t('accountSettings.billing.invoicesModal.title')}}</p>
                    <v-data-table
                        :items="invoices"
                        :headers="headers"
                        :items-per-page-options="[5, 10, 20]"
                        items-per-page="5"
                        class="border border-thin rounded-lg"
                    >
                        <template v-slot:item.description="{ item }">
                            <a class="exie-link" :href="item.invoice_pdf" target="_blank">
                                {{ item.description }}
                            </a>
                        </template>
                        <template v-slot:item.total="{ value }">
                            <span>USD {{ currencyFormat(value/100) }}</span>
                        </template>
                        <template v-slot:item.created="{ value }">
                            <span>{{ getFullDate(value) }}</span>
                        </template>
                        <template v-slot:item.status="{ value }">
                            <span class="text-capitalize" :class="{'text-green': value == 'paid'}">{{ value }}</span>
                        </template>
                    </v-data-table>
                </div>
                <p>
                    <v-btn variant="flat" class="float-right ma-5" @click="isActive.value = false">
                        {{$t('accountSettings.billing.invoicesModal.btnClose')}}
                    </v-btn>
                </p>
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js'
import api from '@/utils/api'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import {currencyFormat, getFullDate} from '@/utils/dataFormatter'

export default {
    setup() {
        const invoices = ref([])

        const headers = [
            {title: "Invoices", key: "description"},
            {title: "Amount", key: "total"},
            {title: "Date", key: "created"},
            {title: "Status", key: "status"}
        ]
        const route = useRoute()
        const accountId = route.params.account_id

        const loadInvoices = async () => {
            const response = await api.get(`/api/billing/${accountId}/invoices`)
            invoices.value = response?.invoices ?? []
        }

        loadInvoices()

        return {
            mdiClose,
            invoices,
            headers,
            currencyFormat,
            getFullDate
        }
    }
}
</script>

<style>
div.v-data-table-footer__pagination > nav {
    padding-top: 0px;
    padding-bottom: 0px;
}
</style>