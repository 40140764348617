<template>
    <v-dialog
      v-model="dialog"
      min-width="400px"
      persistent
      
    >
      <template v-slot:activator="{ props: activatorProps }">
        <v-btn size="large" color="primary" v-bind="activatorProps">
          {{ $t('accountSettings.team.btnInviteTitle') }}
        </v-btn>
      </template>
      <div class="w-100 d-flex justify-center">
          <v-card min-height="400px" min-width="600px" max-width="800px" rounded="lg" class="d-flex flex-column">
            <p class="mt-2 mr-2 text-right float-start">
                <v-btn size="x-small" color="grey-lighten-4" flat :icon="mdiClose" @click="closeModal"></v-btn>
            </p>
            <p class="px-5 mb-16 text-center exie-text-h5">{{ $t('accountSettings.team.inviteModalTitle') }}</p>
            <div class="my-16 d-flex align-center justify-center">
                <div>
                    <div v-if="!invitationLink">
                        <v-btn size="large" color="primary" @click="generateInvitationLink" :disabled="processing">{{ $t('accountSettings.team.btnGenerateLinkTitle') }}</v-btn>
                    </div>
                    <div v-else>
                        <v-chip class="cursor-pointer" @click="copyLink">{{ invitationLink }}</v-chip>
                    </div>
                    
                </div>
            </div>
            <p class="mt-16 pa-5">
                <v-btn class="float-right" variant="flat" @click="closeModal">
                    {{ $t('accountSettings.team.btnInvitationModalClose') }}
                </v-btn>
            </p>
          </v-card>
      </div>
        <v-snackbar
            v-model="copied"
            :timeout="3000"
        >
            {{ $t('accountSettings.team.invitationLinkCopied') }}
        </v-snackbar>
    </v-dialog>
</template>

<script>
import { ref } from 'vue'
import api from '@/utils/api'
import { useRoute } from 'vue-router'
import { mdiClose } from '@mdi/js'

export default {
    setup() {
        const dialog = ref(false)
        const invitationLink = ref(null)
        const processing = ref(false)
        const route = useRoute()
        const copied = ref(false)

        const generateInvitationLink = async () => {
            processing.value = true

            const response = await api.post(`/api/team/${route.params.account_id}/invitation`)
            if(!response.error) {
                invitationLink.value = response.invitation.link
            }

            processing.value = false
        }

        const closeModal = () => {
            invitationLink.value = null
            dialog.value = false
            copied.value = false
        }

        const copyLink = () => {
            navigator.clipboard.writeText(invitationLink.value)
            copied.value = true
        }

        return {dialog, invitationLink, processing, generateInvitationLink, closeModal, copied, copyLink, mdiClose}
    }
}
</script>