<template>
    <v-system-bar @click="handleReloadApp" class="cursor-pointer text-center" color="purple-darken-3" height="32" >{{ $t('appUpdater.title') }}</v-system-bar>
</template>

<script>
export default {
    setup() {
        const handleReloadApp = () => {
            window.location.reload()
        }

        return { handleReloadApp }
    }
}
</script>

