<template>
    <v-sheet class="mt-5" width="100%">
        <p class="mb-5"><v-btn :to="{name: 'train', params: {account_id: accountId}}">{{$t('document.btnGoBack')}}</v-btn></p>
        <v-card class="pa-5">
            <div v-if="document">
                <h3 class="mb-5">{{$t('document.title')}} {{ document.document_ref_id }}</h3>
                <v-textarea 
                    variant="solo" 
                    counter 
                    :placeholder="$t('document.textPlaceholder')" 
                    rows="20"
                    no-resize
                    v-model="documentData"
                >
                </v-textarea>
                <p class="d-flex justify-space-between">
                    <v-btn variant="text" color="red" @click="handleShowDeleteModal">{{$t('document.btnDelete')}}</v-btn>
                    <v-btn variant="flat" :disabled="saving" @click="handleSave" color="grey-lighten-4">{{$t('document.btnSave')}}</v-btn>
                </p>
            </div>
            <div v-if="error"> {{ error }}</div>
        </v-card>
    </v-sheet>

    <v-dialog
      v-model="showDeleteModal"
      width="516"
    >
      <v-card
        max-width="516"
      >
        <p class="mt-2 mr-2 text-right">
            <v-btn size="x-small" color="grey-lighten-4" flat :icon="mdiClose" @click="handleCloseDeleteModal"></v-btn>
        </p>
        <p class="px-5 text-center exie-text-h5">{{$t('sources.text.documents.deleteTitle')}}</p>
        <div class="pa-5 my-8">
            <p class="text-center exie-text-body-1">{{$t('sources.text.documents.deleteDescription')}}</p>
        </div>
        <p class="d-flex justify-space-between pa-5">
            <v-btn variant="flat" color="grey-lighten-4" @click="handleCloseDeleteModal">{{$t('sources.text.documents.btnDeleteClose')}}</v-btn>
            <v-btn variant="text" color="red" @click="handleDelete">{{$t('sources.text.documents.btnDeleteConfirm')}}</v-btn>
        </p>
      </v-card>
    </v-dialog>

    <v-snackbar location="top right" color="success" v-model="successfullyIndexed" :timeout="4000">
        {{$t('sources.text.notify.successIndex')}}
    </v-snackbar>
</template>

<script>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useDocumentsStore } from '@/stores/DocumentsStore.js'
import api from '@/utils/api'
import { mdiClose } from '@mdi/js'

export default {
    setup() {
        const route = useRoute()
        const router = useRouter()
        const documentsStore = useDocumentsStore()
        const accountId = route.params.account_id
        const documentId = route.params.document_id

        const successfullyIndexed = ref(false)
        const saving = ref(false)

        const showDeleteModal = ref(false)

        const error = ref(null)
        const document = ref(null)
        const documentData = ref(null)

        const loadDocument = async () => {
            const responseData = await api.get(`/api/documents/${accountId}/${documentId}`)

            if (responseData.error) {
                error.value = responseData.error
                return
            }

            document.value = responseData
            documentData.value = responseData.data
        }

        const handleSave = async () => {
            saving.value = true
            const response = await api.put(`/api/ai/${accountId}/update-text/${documentId}`, {
                document: documentData.value
            })

            if(response.error) {
                error.value = response.error
                return
            }

            documentsStore.updateDocument(response)
            successfullyIndexed.value = true
            saving.value = false
        }

        const handleDelete = async () => {
            const responseData = await api.delete(`/api/documents/${accountId}/${documentId}`)
            if(responseData.status === 'success') {
                documentsStore.deleteDocumentFromStore(documentId)
                router.push({name: 'train'})
            }
            handleCloseDeleteModal()
        }

        const handleShowDeleteModal = () => {
            showDeleteModal.value = true
        }

        const handleCloseDeleteModal = () => {
            showDeleteModal.value = false
        }

        loadDocument()

        return { 
            error, 
            document, 
            accountId, 
            handleDelete, 
            handleShowDeleteModal, 
            handleCloseDeleteModal, 
            showDeleteModal, 
            handleSave,
            documentData,
            successfullyIndexed,
            saving,
            mdiClose
        }
    }
}
</script>