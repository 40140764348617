<template>
    <v-sheet>
        <p class="exie-text-subtitle-1 font-weight-bold mb-2">{{$t('accountSettings.general.instagram.title')}}</p>   
        <v-row>
            <v-col cols="12" sm="6" md="5">
                <p class="exie-text-body-1 text-pre-line pr-3"> {{$t('accountSettings.general.instagram.description')}} </p>
                <v-list>
                    <v-list-item 
                        :title="appStateStore.data.currentAccount.name"
                        :subtitle="appStateStore.data.currentAccount.instagram_username" 
                    >
                        <template v-slot:prepend>
                            <v-avatar color="grey-darken-2" :image="appStateStore.data.currentAccount.picture" />
                        </template>
                    </v-list-item>
                </v-list>
            </v-col>
            <v-col cols="12" sm="6" md="7">
                <v-btn :disabled="refreshingToken" :loading="refreshingToken" @click="handleRefreshToken" color="secondary">{{$t('accountSettings.general.instagram.btnRefreshToken')}}</v-btn>
            </v-col>
        </v-row>
    </v-sheet>
    <v-snackbar location="top right" color="error" v-model="error" :timeout="3000">
        {{errorMessage}}
    </v-snackbar>
    <v-snackbar location="top right" color="success" v-model="success" :timeout="3000">
        {{$t('accountSettings.general.instagram.notify.successRefresh')}}
    </v-snackbar>
</template>

<script>
import { useAppStateStore } from '@/stores/AppStateStore'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import api from '@/utils/api'
export default {
    setup() {
        const appStateStore = useAppStateStore()
        const route = useRoute()
        const accountId = route.params.account_id

        const error = ref(false)
        const errorMessage = ref(null)
        const success = ref(false)

        const refreshingToken = ref(false)

        const handleRefreshToken = async () => {
            refreshingToken.value = true
            const responseData = await api.post(`/api/fb/refresh-ig-token/${accountId}`)

            if (responseData.error) {
                error.value = true
                errorMessage.value = responseData.error
                return
            }
            if (responseData.redirect) {
                window.location.href = responseData.redirect
                return
            }
            appStateStore.updateCurrentAccount(responseData, true)
            refreshingToken.value = false
            success.value = true
        }

        return { appStateStore, error, errorMessage, handleRefreshToken, success, refreshingToken }
    }
}
</script>