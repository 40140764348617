<template>
    <v-sheet>
        <div class="d-flex flex-column align-center justify-center text-center pt-8" :class="{'centered': mdAndUp}">
            <v-img 
                src="/img/login_logo.svg"
                height="98"
                width="98"
            />
            <h4 class="exie-text-h4 mt-8">{{ $t('login.title') }}</h4>
            <p class="exie-text-body-1 mt-8" :style="{maxWidth: '610px'}">{{ $t('login.description') }}</p>
            <div class="mt-8" :style="{maxWidth: '400px'}">
                <v-btn :disabled="btnDisabled" color="primary" size="large" block @click="handleLogin">
                    {{ $t('login.btnRegisterFb') }}
                </v-btn>
                <v-btn class="mt-3" :disabled="btnDisabled" color="grey-lighten-4" size="large" block @click="handleLogin">
                    {{ $t('login.btnLoginFb') }}
                </v-btn>
            </div>
            <div class="text-caption mt-3 text-pre-line">
                <i18n-t keypath="login.agreeTerms.text" tag="p">
                    <template v-slot:tosLink>
                        <a class="exie-link" href="https://legal.exie.app/tos" target="_blank">{{ $t('login.agreeTerms.tosLink') }}</a>
                    </template>
                    <template v-slot:ppLink>
                        <a class="exie-link" href="https://legal.exie.app/privacy-policy" target="_blank">{{ $t('login.agreeTerms.ppLink') }}</a>
                    </template>
                </i18n-t>
            </div>
        </div>
    </v-sheet>
</template>

<script>
import { useAppStateStore } from '@/stores/AppStateStore'
import { useRoute, useRouter } from 'vue-router'
import { useMixPanelStore } from '@/stores/MixPanelStore'
import { useDisplay } from 'vuetify'
import api from '@/utils/api'
import { ref } from 'vue'
export default {
    setup() {
        const appStateStore = useAppStateStore()
        const router = useRouter()
        const route = useRoute()
        const mixPanelStore = useMixPanelStore()

        const {mdAndUp} = useDisplay()

        const btnDisabled = ref(false)
        
        const handleLogin = async () => {
            mixPanelStore.track('f_click_fb_login_button')
            btnDisabled.value = true
            const json = await api.post("/api/auth/fb/sign-in", {
                return_path: route?.query?.returnPath
            })

            window.location.href = json.redirect
        }

        if (appStateStore.data.user) {
            router.push({
                name: 'home'
            })
        }

        return { handleLogin, btnDisabled, mdAndUp }
    }
}
</script>

<style>
.mh90 {
    min-height: 90dvh;
}
.centered {
    position: absolute;               /* 2 */
    left: 50%;                         /* 3 */
    transform: translate(-50%, 0)
}
</style>