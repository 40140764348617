<template>
    <v-sheet>
        <p class="exie-text-subtitle-1 font-weight-bold mb-2">Shopify</p>   
        <div class="mt-3 ">
            <v-row v-if="!integrationLoaded">
                <v-col cols="12" sm="6" md="5">
                    <v-skeleton-loader width="100%" type="paragraph"></v-skeleton-loader>
                </v-col>
                <v-col cols="12" sm="6" md="7">
                    <v-skeleton-loader width="100%" type="paragraph"></v-skeleton-loader>
                </v-col>
            </v-row>
            <v-row v-else-if="!integration">
                <v-col cols="12" sm="6" md="5">
                    <p class="exie-text-body-1 text-pre-line pr-3">{{ $t('accountSettings.integrations.shopify.connectDescription') }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="7">
                    <p>
                        <v-text-field density="comfortable" v-model="shopifyDomain" rounded="lg" hide-details flat variant="solo-filled" :placeholder="$t('accountSettings.integrations.shopify.shopifyDomainPlaceholder')">
                            <template v-slot:append-inner>
                                <span>.myshopify.com</span>
                            </template>
                        </v-text-field>
                    </p>
                    <v-btn :disabled="!shopifyDomain" class="mt-3" color="primary" @click="handleConnectStore">{{ $t('accountSettings.integrations.shopify.btnConnect') }}</v-btn>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col cols="12" sm="6" md="5">
                    <p class="exie-text-body-1 text-pre-line pr-3">{{ $t('accountSettings.integrations.shopify.connectedDescription') }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="7">
                    <p>
                        <v-text-field readonly density="comfortable" v-model="shopifyDomain" rounded="lg" hide-details flat variant="solo-filled">
                            <template v-slot:append-inner>
                                <span>.myshopify.com</span>
                            </template>
                        </v-text-field>
                    </p>
                    <p class="mt-8">
                        <v-btn class="float-right" @click="handleOpenDisconnectModal">{{ $t('accountSettings.integrations.shopify.btnDisconnect') }}</v-btn>
                    </p>
                </v-col>
            </v-row>
        </div>
    </v-sheet>
    <!-- Disconnect integration modal -->
    <v-dialog
      v-model="showDisconnectModal"
      width="516"
    >
      <v-card
        max-width="516"
        rounded="lg"
        class="px-2"
      >
        <p class="mt-2 mr-2 text-right">
            <v-btn size="x-small" color="grey-lighten-4" flat :icon="mdiClose" @click="handleCloseDisconnectModal"></v-btn>
        </p>
        <p class="px-5 text-center exie-text-h5">{{$t('accountSettings.integrations.shopify.disconnectTitle')}}</p>
        <div class="pa-5 my-8">
            <p class="text-center exie-text-body-1">{{$t('accountSettings.integrations.shopify.disconnectDescription')}}</p>
        </div>
        <p class="d-flex justify-space-between pa-5">
            <v-btn variant="flat" color="grey-lighten-4" @click="handleCloseDisconnectModal">{{$t('accountSettings.integrations.shopify.btnDisconnectClose')}}</v-btn>
            <v-btn variant="text" color="red" @click="handleDisconnect">{{$t('accountSettings.integrations.shopify.btnDisconnectConfirm')}}</v-btn>
        </p>
      </v-card>
    </v-dialog>
</template>
<script>
import {ref} from 'vue'
import api from '@/utils/api'
import {useRoute} from 'vue-router'
import {mdiClose} from '@mdi/js'
export default {
    setup() {
        const route = useRoute()
        const shopifyDomain = ref(null)

        const accountId = route.params.account_id

        const integration = ref(null)
        const integrationLoaded = ref(false)

        const showDisconnectModal = ref(false)

        const handleConnectStore = async () => {
            if(!shopifyDomain.value) {
                return
            }
            const response = await api.post(`/api/integrations/shopify/${accountId}/login`, {
                shop: shopifyDomain.value
            })
            
            if(response.redirect) {
                window.location.href = response.redirect
            }
        }

        const loadIntegration = async () => {
            const response = await api.get(`/api/integrations/shopify/${accountId}/integration`)
            if(response.integration) {
                integration.value = response.integration
                shopifyDomain.value = response.integration.shop_domain
            }
            integrationLoaded.value = true
        }

        const handleCloseDisconnectModal = () => {
            showDisconnectModal.value = false
        }

        const handleOpenDisconnectModal = () => {
            showDisconnectModal.value = true
        }

        const handleDisconnect = async () => {
            const response = await api.post(`/api/integrations/shopify/${accountId}/disconnect`)
            handleCloseDisconnectModal()
            if (response.error) {
                error.value = true
                return
            }

            integration.value = null
            shopifyDomain.value = null
        }

        loadIntegration()

        return {
            shopifyDomain,
            handleConnectStore,
            integration,
            integrationLoaded,
            showDisconnectModal,
            handleCloseDisconnectModal,
            handleOpenDisconnectModal,
            handleDisconnect
        }
    }
}
</script>