<template>
    <v-sheet>
        <p class="exie-text-subtitle-1 font-weight-bold mb-2 text-red">{{$t('accountSettings.general.deleteAccount.title')}}</p>   
        <v-row>
            <v-col cols="12" sm="6" md="5">
                <p class="exie-text-body-1 text-pre-line pr-3"> {{$t('accountSettings.general.deleteAccount.description')}} </p>
            </v-col>
            <v-col cols="12" sm="6" md="7">
                <v-btn :loading="processing" :disabled="processing" color="red" @click="handleOpenDeleteModal">{{$t('accountSettings.general.deleteAccount.btnDelete')}}</v-btn>
            </v-col>
        </v-row>
    </v-sheet>
    <!--deleteModal-->
    <v-dialog
      v-model="deleteSign"
      width="516"
    >
      <v-card
        max-width="516"
        rounded="lg"
        class="px-2"
      >
        <p class="mt-2 mr-2 text-right">
            <v-btn size="x-small" color="grey-lighten-4" flat :icon="mdiClose" @click="handleCloseDeleteModal"></v-btn>
        </p>
        <p class="px-5 text-center exie-text-h5">
            <v-icon class="mr-3" size="48" color="red" :icon="mdiAlertOutline"></v-icon>
            {{$t('accountSettings.general.deleteAccount.deleteModalTitle')}}
        </p>
        <div class="pa-5 my-8">
            <p class="text-center exie-text-body-1">{{$t('accountSettings.general.deleteAccount.deleteModalDescription1')}}</p>
            <p class="text-center exie-text-body-1 font-weight-bold">{{$t('accountSettings.general.deleteAccount.deleteModalDescription2')}}</p>
        </div>
        <p class="d-flex justify-space-between pa-5">
            <v-btn variant="flat" color="grey-lighten-4" @click="handleCloseDeleteModal">{{$t('accountSettings.general.deleteAccount.btnDeleteModalClose')}}</v-btn>
            <v-btn :loading="processing" :disabled="processing" variant="text" color="red" @click="handleDeleteAccount">{{$t('accountSettings.general.deleteAccount.btnDeleteModalConfirm')}}</v-btn>
        </p>
      </v-card>
    </v-dialog>
    <v-snackbar location="top right" color="error" v-model="error" :timeout="3000">
        {{ $t('accountSettings.general.deleteAccount.notifyError') }}
    </v-snackbar>
</template>
<script>
import { ref } from 'vue'
import {mdiClose, mdiAlertOutline} from '@mdi/js'
import api from '@/utils/api'
import {useRoute} from 'vue-router'
export default {
    setup() {
        const deleteSign = ref(null)
        const processing = ref(false)
        const route = useRoute()
        const error = ref(false)

        const handleOpenDeleteModal = async () => {
            processing.value = true
            const response = await api.get(`/api/account/${route.params.account_id}/delete`)
            if (!response.key) {
                error.value = true
                processing.value = false
                return
            }
            deleteSign.value = response.key
            processing.value = false
        }

        const handleCloseDeleteModal = async () => {
            deleteSign.value = null
        }

        const handleDeleteAccount = async () => {
            processing.value = true
            const response = await api.post(`/api/account/${route.params.account_id}/delete`, {
                key: deleteSign.value
            })

            processing.value = false
            if(response.error) {
                error.value = true
            }

            deleteSign.value = null
        }
        

        return {
            deleteSign,
            processing,
            mdiClose,
            mdiAlertOutline,
            handleOpenDeleteModal,
            handleCloseDeleteModal,
            handleDeleteAccount,
            error
        }
    }
}
</script>