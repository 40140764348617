<template>
    <div v-if="isCurrentAccountOwner" class="w-100 h-100">
        <p class="exie-text-subtitle-1 font-weight-bold mb-2">{{$t('accountSettings.billing.title')}}</p>  
        <v-row>
            <v-col cols="12" sm="6" md="5">
                <p class="exie-text-body-1 text-pre-line"> {{$t('accountSettings.billing.description')}} </p>
            </v-col>
        </v-row>
        <v-row class="mb-5">
            <v-col cols="12" md="6" lg="5">
                <CurrentPlan />
            </v-col>
        </v-row>
        <AllPlans /> 
        <Addons />
    </div>
    <div v-else class="w-100 h-100">
        <p class="exie-text-subtitle-1 font-weight-bold mb-2">{{$t('accountSettings.billing.permissionsFailedTitle')}}</p> 
        <v-row>
            <v-col cols="12" sm="6" md="5">
                <p class="exie-text-body-1 text-pre-line"> {{$t('accountSettings.billing.permissionsFailedDescription')}} </p>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import api from '@/utils/api'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { useAppStateStore } from '@/stores/AppStateStore'
import { storeToRefs } from 'pinia'
import CurrentPlan from './billing/CurrentPlan.vue'
import AllPlans from './billing/AllPlans.vue'
import Addons from './billing/Addons.vue'
export default {
    components: {CurrentPlan, AllPlans, Addons},
    setup() {
        const appStateStore = useAppStateStore()
        const { isCurrentAccountOwner, currentAccount } = storeToRefs(appStateStore)
        const route = useRoute()
        const subscription = ref(null)
        const initialized = ref(false)
        const accountId = route.params.account_id
        const getSubscription = async() => {
            const response = await api.get(`/api/billing/${accountId}/subscription`)
            subscription.value = response.subscription
            initialized.value = true

        }

        const cancelSubscription = async() => {
            const response = await api.delete(`/api/billing/${accountId}/subscription`)
            subscription.value = response.subscription

        }

        const resumeSubscription = async() => {
            const response = await api.put(`/api/billing/${accountId}/subscription`)
            subscription.value = response.subscription

        }

        getSubscription()

        return {subscription, accountId, initialized, cancelSubscription, resumeSubscription, isCurrentAccountOwner, currentAccount}
    }
}
</script>