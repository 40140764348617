import i18n from '@/i18n/i18n'

const api = {
    get: async (url) => {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'x-locale': i18n.global.locale.value
            }
        })
        return await response.json()
    },
    post: async (url, body) => {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-locale': i18n.global.locale.value
            },
            body: JSON.stringify(body)
        })
        return await response.json()
    },
    put: async (url, body) => {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'x-locale': i18n.global.locale.value
            },
            body: JSON.stringify(body)
        })
        return await response.json()
    },
    patch: async (url, body) => {
        const response = await fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'x-locale': i18n.global.locale.value
            },
            body: JSON.stringify(body)
        })
        return await response.json()
    },
    delete: async (url) => {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'x-locale': i18n.global.locale.value
            }
        })
        return await response.json()
    }
}

export default api