<template>
    <v-card v-if="currentAccount" flat rounded="xl" color="grey-lighten-4" height="100%" min-height="250px">
        <p class="mt-3 mx-6 exie-text-subtitle-1 font-weight-bold">
            <span>{{$t('dashboard.widget.account.title')}}</span>
            <v-chip rounded="lg" variant="flat" class="float-right" :color="getProStatusLabelColor(currentAccount.pro_status)">{{ getProStatusLabel(currentAccount.pro_status) }}</v-chip>
        </p>
        <v-card-text v-if="currentAccount" class="d-flex w-100 flex-column justify-center align-center">
            <div class="my-3">
                <v-avatar color="grey-darken-3" :image="currentAccount.picture" size="96" />
            </div>
            
            <div><span class="exie-text-subtitle-1">{{ currentAccount.name }}</span></div>
            <div><span class="exie-text-caption">{{ currentAccount.instagram_username }}</span></div>
        </v-card-text>
    </v-card>
</template>

<script>
import { getProStatusLabel, getProStatusLabelColor } from '@/utils/AccountUtils'
import { useAppStateStore } from '@/stores/AppStateStore'
import { storeToRefs } from 'pinia'

export default {
    setup() {
        const appStateStore = useAppStateStore()
        const {currentAccount} = storeToRefs(appStateStore)

        return {currentAccount, getProStatusLabel, getProStatusLabelColor }
    }
}
</script>