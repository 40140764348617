/* eslint-disable no-console */

import { register } from 'register-service-worker'
const APP_VERSION = process.env.VUE_APP_VERSION

  register(`/service-worker.js?sv=${APP_VERSION}`, {
    ready () {
      
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated (event) {
      console.log('New content is available; please refresh.')
      if(event.waiting) {
        console.log('skip waiting')
        event.waiting.postMessage({ type: 'SKIP_WAITING' })
        document.dispatchEvent(
          new CustomEvent('swUpdated', { detail: event })
        )
      }
      
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
