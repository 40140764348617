<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <div class="w-100 d-flex flex-column align-center pa-5 exie-text-body-1">
                    <p>
                        {{ $t('accountSettings.integrations.connectionError.title')}}
                    </p>
                    <p>
                        <v-btn class="mt-5" color="primary" :to="{path: `/account/settings#integrations`}">{{ $t('accountSettings.integrations.connectionError.btnGoToSettings') }}</v-btn>
                    </p>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
</script>