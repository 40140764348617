<template>
    <v-container>
        <h4 class="exie-text-h4 mb-3">{{$t('accountSettings.title')}}</h4>
        <v-row class="mt-3" v-if="appStateStore.data.currentAccount">
            <v-col cols="12">
                <v-tabs color="primary" direction="horizontal" class="mt-3 mb-5" v-model="currentTab">
                    <v-tab class="px-3 text-uppercase" :ripple="false" value="general">{{$t('accountSettings.generalTitle')}}</v-tab>
                    <v-tab class="px-3 text-uppercase" :ripple="false" value="team">{{$t('accountSettings.teamTitle')}}</v-tab>
                    <v-tab class="px-3 text-uppercase" :ripple="false" value="integrations">{{ $t('accountSettings.integrationsTitle') }}</v-tab>
                    <v-tab class="px-3 text-uppercase" :ripple="false" value="billing">{{$t('accountSettings.billingTitle')}}</v-tab>
                </v-tabs>
                <v-sheet v-if="currentTab === 'general'">
                    <General />
                </v-sheet>
                <v-sheet v-if="currentTab === 'integrations'">
                    <Integrations />
                </v-sheet>
                <v-sheet v-if="currentTab === 'team'">
                    <Team />
                </v-sheet>
                <v-sheet v-if="currentTab === 'billing'">
                    <Billing />
                </v-sheet>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import General from '@/components/accountSettings/General.vue'
import Billing from '@/components/accountSettings/Billing.vue'
import Team from '@/components/accountSettings/Team.vue'
import Integrations from '@/components/accountSettings/Integrations.vue'
import { ref } from 'vue';
import { useAppStateStore } from '@/stores/AppStateStore'
import { useDisplay } from 'vuetify'
import { useRoute, useRouter } from 'vue-router'
import { watch } from 'vue'
import {useAccountDataStore} from '@/stores/AccountDataStore'
export default {
    components: { General, Billing, Team, Integrations },
    setup() {
        const availableHashes = ['#billing', '#team', '#integrations', '#general']
        const router = useRouter()
        const route = useRoute()
        const currentTab = ref('general')

        if(availableHashes.includes(route.hash)) {
            currentTab.value = route.hash.slice(1)
        }

        watch(currentTab, (newVal) => {
            router.replace({name: route.name, params: route.params, hash: `#${newVal}`})
        })
        
        const appStateStore = useAppStateStore()
        const { xs } = useDisplay()

        return { currentTab, appStateStore, xs }
    }
}
</script>