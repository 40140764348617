<template>
    <v-container class="w-100">
        <h4 class="exie-text-h4">{{$t('sources.title')}}</h4>
        <v-row class="mt-3">
            <v-col cols="12">
                <v-tabs color="primary" direction="horizontal" class="mt-3 mb-5" v-model="currentTab">
                    <v-tab class="px-12 text-uppercase" :ripple="false" value="text">{{$t('sources.textTitle')}}</v-tab>
                    <v-tab class="px-12 text-uppercase" :ripple="false" value="qna">{{$t('sources.qnaTitle')}}</v-tab>
                </v-tabs>
                <v-sheet v-if="currentTab === 'text'">
                    <TextTrain />
                </v-sheet>
                <v-sheet v-if="currentTab === 'qna'">
                    <QnaTrain />
                </v-sheet>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useDocumentsStore } from '@/stores/DocumentsStore'
import TextTrain from '@/components/train/TextTrain.vue'
import QnaTrain from '@/components/train/QnaTrain.vue'
import { useDisplay } from 'vuetify'
export default {
    components: { TextTrain, QnaTrain },
    setup() {
        const { xs } = useDisplay()
        const route = useRoute()
        const accountId = route.params.account_id
        const documentsStore = useDocumentsStore()
        documentsStore.loadDocuments(accountId)
        const currentTab = ref('text')
        return { currentTab, xs }
    }
}
</script>

<style scoped>
.card {
    width: 60dvw;
}
</style>