<template>
    
</template>

<script>
import { useAccountStatsStore } from '@/stores/AccountStatsStore'
import { Centrifuge } from 'centrifuge'
import { storeToRefs } from 'pinia'
import { watch } from 'vue'
import { useAppStateStore } from '@/stores/AppStateStore'
import { useAccountInvitationsStore } from '@/stores/AccountInvitationsStore'
import { useAccountMembersStore } from '@/stores/AccountMembersStore'
import { useAccountSubscriptionStore } from '@/stores/AccountSubscriptionStore'
    export default {
        setup() {
            
            const appStateStore = useAppStateStore()
            const accountStatsStore = useAccountStatsStore()
            const accountInvitationsStore = useAccountInvitationsStore()
            const accountMembersStore = useAccountMembersStore()
            const {userId, currentAccountId} = storeToRefs(appStateStore) 

            const accountSubscriptionStore = useAccountSubscriptionStore()

            const userChannelsSubscriptions = {}
            const accountChannelSubscriptions = {}
            
            let client = null
            const initWsClient = () => {
                if(client) {
                    client.disconnect()
                }
                if (userId) {
                    
                    client = new Centrifuge(process.env.VUE_APP_WEBSOCKET_URL, {
                        token: appStateStore.user.socketToken
                    })
                    client.connect()
                    if(!userChannelsSubscriptions[userId]) {
                        try {
                            userChannelsSubscriptions[userId] = client.newSubscription(`channel_user_${userId.value}`)
                        } catch (e) {
                            userChannelsSubscriptions[userId] = client.getSubscription(`channel_user_${userId.value}`)
                        }
                        userChannelsSubscriptions[userId].on('publication', (ctx) => {
                            // console.log('user socket', ctx)
                            switch(ctx.data.type) {
                                case 'account_user_updated':
                                    appStateStore.updateAccountUserRole(ctx.data.payload)
                                    return
                                case 'account_user_created':
                                    appStateStore.addAccountUserRole(ctx.data.payload)
                                    return
                                case 'account_user_deleted':
                                    appStateStore.deleteAccountUserRole(ctx.data.payload)
                                    return
                                default:
                                    return
                            }
                        }).subscribe()
                    }
                }
                
                subscribeToAccountChannel()
                
            }

            const subscribeToAccountChannel = () => {
                if (client && currentAccountId.value) {
                    if(!accountChannelSubscriptions[currentAccountId.value]) {
                        try {
                            accountChannelSubscriptions[currentAccountId.value] = client.newSubscription(`channel_account_${currentAccountId.value}`)
                        } catch (e) {
                            accountChannelSubscriptions[currentAccountId.value] = client.getSubscription(`channel_account_${currentAccountId.value}`)
                        }
                        accountChannelSubscriptions[currentAccountId.value].on('publication', (ctx) => {
                            // console.log('account socket', ctx)
                            switch(ctx.data.type) {
                                case 'available_messages':
                                    accountStatsStore.updateMessagesAvailable(ctx.data.payload.available_messages)
                                    if(ctx.data.payload.messages_limit != undefined) {
                                        accountStatsStore.updateMessagesLimit(ctx.data.payload.messages_limit)
                                    }
                                    if(ctx.data.payload.extra_messages_available != undefined) {
                                        accountStatsStore.updateExtraMessagesAvailable(ctx.data.payload.extra_messages_available)
                                    }
                                    if(ctx.data.payload.subscription_messages_available != undefined) {
                                        accountStatsStore.updateSubscriptionMessagesAvailable(ctx.data.payload.subscription_messages_available)
                                    }
                                    return
                                case 'account_updated':
                                    appStateStore.updateAccount(ctx.data.payload)
                                    return
                                case 'invitation_created':
                                    accountInvitationsStore.addInvitation(ctx.data.payload)
                                    return
                                case 'invitation_deleted':
                                    accountInvitationsStore.deleteInvitation(ctx.data.payload)
                                    return
                                case 'account_user_deleted':
                                    accountMembersStore.deleteMember(ctx.data.payload)
                                    return
                                case 'account_user_created':
                                    accountMembersStore.addMember(ctx.data.payload)
                                    return
                                case 'subscription_updated':
                                    accountSubscriptionStore.update(currentAccountId.value, ctx.data.payload)
                                    return
                                case 'subscription_deleted':
                                    accountSubscriptionStore.delete(currentAccountId.value)
                                    return
                                default:
                                    return
                            }
                        }).subscribe()
                    }
                }
            }

            watch(userId, (newVal) => {
                if(newVal == null) {
                    if(client) {
                        client.disconnect()
                    }
                } else {
                    initWsClient()
                }
            })

            watch(currentAccountId, (newVal) => {
                subscribeToAccountChannel()
            })

            
            
        }
    }
</script>