<template>

</template>

<script>
import {useRoute, useRouter} from 'vue-router'
import { useAppStateStore } from '@/stores/AppStateStore'
import { storeToRefs } from 'pinia'
export default {
    setup() {
        const route = useRoute()
        const router = useRouter()
        const appStateStore = useAppStateStore()
        const {currentAccountId} = storeToRefs(appStateStore)

        const path = route.path.split('/')

        if(currentAccountId) {
            router.push(`/${path[1]}/${currentAccountId.value}/${path[2]}${route.hash}`)
        }
    }
}
</script>