<template>
    <v-sheet class="w-100 h-100">
        <div v-if="!initialized">
            <v-progress-linear indeterminate></v-progress-linear>
        </div>
        <div v-else class="w-100 d-flex justify-center" >
            <div v-if="!invitation" class="mt-5">
                Invitation not found
            </div>
            <div v-else class="text-center" >
                <p class="exie-text-h5 mb-3">Do you really want to join?</p>
                <p><v-avatar :image="invitation.account.picture" size="64"></v-avatar></p>
                <p class="exie-text-subtitle-1">{{ invitation.account.name }}</p>
                <v-btn @click="handleJoin" color="primary" class="mt-3">Join</v-btn>
            </div>
        </div> 
    </v-sheet>
    <v-snackbar location="top right" color="error" v-model="error" :timeout="4000">
        {{error}}
    </v-snackbar>
</template>

<script>
import api from '@/utils/api'
import { ref } from 'vue'
import {useRoute} from 'vue-router'
export default {
    setup() {
        const initialized = ref(false)
        const invitation = ref(null)
        const error = ref(null)

        const route = useRoute()

        const initialize = async () => {
            const response = await api.get(`/api/team/invitation/${route.params.invitation_code}`)
            if(!response.error) {
                invitation.value = response
            }
            initialized.value = true
        }

        const handleJoin = async () => {
            const response = await api.post(`/api/team/join/${route.params.invitation_code}`)
            if(response.error) {
                error.value = response.error
            }

            window.location.href = response.redirect
        }

        initialize()

        return {initialized, invitation, handleJoin, error}
    }
}
</script>