<template>
    <v-sheet>
        <p class="exie-text-subtitle-1 font-weight-bold mb-2">{{$t('accountSettings.general.notifications.title')}}</p>  
        <v-row>
            <v-col cols="12" sm="6" md="5">
                <p class="exie-text-body-1 text-pre-line pr-3">
                     {{$t('accountSettings.general.notifications.description')}} 
                </p>
                <p v-if="notificationPermission !== 'granted'" class="exie-text-body-1 text-pre-line pr-3 mt-3">
                    <i18n-t keypath="accountSettings.general.notifications.notificationDisabled.text" tag="p">
                        <template v-slot:url>
                            <a class="exie-link" href="https://help.exie.app/Notifications-Explained-f1a4ef8262364868b8c64a766e268be2" target="_blank">{{ $t('accountSettings.general.notifications.notificationDisabled.url') }}</a>
                        </template>
                    </i18n-t>
                </p>
                <p v-else class="exie-text-body-1 text-pre-line pr-3 mt-3">
                    <i18n-t keypath="accountSettings.general.notifications.notificationEnabled.text" tag="p">
                        <template v-slot:url>
                            <a class="exie-link" href="https://help.exie.app/Notifications-Explained-f1a4ef8262364868b8c64a766e268be2" target="_blank">{{ $t('accountSettings.general.notifications.notificationEnabled.url') }}</a>
                        </template>
                    </i18n-t>
                </p>
            </v-col>
            <v-col cols="12" sm="6" md="7">
                <div v-if="notificationPermission !== 'granted'">
                    <div v-if="hasNotifications">
                        <p v-if="notificationPermission === 'default'">
                            <v-btn color="secondary" @click="handleSubscribe">{{$t('accountSettings.general.notifications.btnTurnOn')}}</v-btn>
                        </p>
                        <p v-if="notificationPermission === 'denied'">
                            <v-btn color="secondary" :disabled="true">{{$t('accountSettings.general.notifications.btnNotificationDisabled')}}</v-btn>
                        </p>
                    </div>
                </div>
                <div v-else>
                    <div v-if="pushSubscription">
                        <v-btn color="secondary" @click="testNotification">{{$t('accountSettings.general.notifications.btnTest')}}</v-btn>
                    </div>
                    <div v-else>
                        <v-btn color="secondary" @click="handleSubscribe">{{$t('accountSettings.general.notifications.btnSubscribe')}}</v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-sheet>
    <v-snackbar location="top right" color="success" v-model="success" :timeout="3000">
        {{$t('accountSettings.general.notifications.testSuccess')}}
    </v-snackbar>
    <v-snackbar location="top right" color="error" v-model="error" :timeout="3000">
        {{ errorMessage }}
    </v-snackbar>
    
</template>

<script>
import { ref } from 'vue'
import api from '@/utils/api'
import { useRoute } from 'vue-router'
import { useDisplay } from 'vuetify'
export default {
    setup() {
        const route = useRoute()

        const { platform } = useDisplay()
        const WEB_PUSH_PUBLIC_KEY = 'BIYmLLnbPUu9bz5X6dU83pDQHJtoedElYRjXVyb9Cz02fseGV3J1gZQJRr4XixcgKVj4ZXsuaZt89Y6beQb3bjA'
        /** @type ServiceWorkerRegistration|null */
        let serviceWorkerRegistration = null
        const hasNotifications = window.Notification ? true : false
        const notificationPermission = ref(null)

        const success = ref(false)
        const error = ref(false)
        const errorMessage = ref(null)

        if (hasNotifications) {
            notificationPermission.value = window.Notification.permission
        }
       
        const pushSubscription = ref(null)

        const loadPushManager = async() => {
            if (!hasNotifications) {
                return
            }
            serviceWorkerRegistration = await navigator.serviceWorker.getRegistration('/service-worker.js')
             
            pushSubscription.value = await serviceWorkerRegistration.pushManager.getSubscription()
        }

        loadPushManager()

        const handleSubscribe = async () => {
            if (!hasNotifications) {
                return
            }
            const permission = await Notification.requestPermission()
            notificationPermission.value = permission
            if (notificationPermission.value ==='granted' && !pushSubscription.value) {
                pushSubscription.value = await serviceWorkerRegistration.pushManager.subscribe({
                    applicationServerKey: urlBase64ToUint8Array(WEB_PUSH_PUBLIC_KEY),
                    userVisibleOnly: true
                })
                const response = await api.post(`/api/account/notification-subscription/${route.params.account_id}`, {
                    subscription: pushSubscription.value.toJSON()
                })
            }
        }

        const urlBase64ToUint8Array = (base64String) => {
            const padding = '='.repeat((4 - base64String.length % 4) % 4);
            const base64 = (base64String + padding)
                .replace(/-/g, '+')
                .replace(/_/g, '/');
                
            const rawData = window.atob(base64);
            const outputArray = new Uint8Array(rawData.length);

            for (let i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i);
            }

            return outputArray;
        };

        const testNotification = async () => {
            if (!pushSubscription.value) {
                return
            }
            const response = await api.post(`/api/account/test-notification/${route.params.account_id}`, {
                subscription: pushSubscription.value.toJSON()
            })

            if (response.error) {
                error.value = true
                errorMessage.value = response.error
                return
            }

            success.value = true
        }

        return {pushSubscription, handleSubscribe, notificationPermission, testNotification, success, error, errorMessage, platform, hasNotifications }
    }
}
</script>
