<template>
    <div class="mt-3 py-3 text-medium-emphasis">
        <LocalisationSwitcher />
        <v-list v-if="appStateStore.userId" variant="text" nav>
            <v-list-item :ripple="false" class="font-weight-medium" color="primary" :to="{name: 'accounts'}">
                <v-icon class="mr-2" :icon="mdiAccountBoxOutline"></v-icon> {{ $t("header.myAccounts") }}
            </v-list-item>
            <v-list-item :ripple="false" class="font-weight-medium" color="primary" :to="{name: 'help'}">
                <v-icon class="mr-2" :icon="mdiHelpCircleOutline"></v-icon> {{ $t("header.help") }}
            </v-list-item>
            <v-list-item :ripple="false" color="primary" class="font-weight-medium" @click="handleLogout">
                <v-icon class="mr-2" :icon="mdiLogout"></v-icon> {{ $t("header.logout") }}
            </v-list-item>
        </v-list>
        <div v-if="accountId && !currentAccount?.is_pro" class="mt-2">
            <v-btn block color="primary" variant="flat">
                {{$t('accountSettings.billing.btnUpgradeToPRO')}}
                <UpgradeToProModal />
            </v-btn>
        </div>
    </div>
</template>

<script>
import api from '@/utils/api'
import { useAppStateStore } from '@/stores/AppStateStore'
import LocalisationSwitcher from '@/components/i18n/LocalisationSwitcher.vue'
import { useDisplay } from 'vuetify'
import { useRouter, useRoute } from 'vue-router'
import { mdiAccountBoxOutline, mdiLogout, mdiHelpCircleOutline } from '@mdi/js'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import UpgradeToProModal from './accountSettings/billing/UpgradeToProModal.vue'

export default {
    components: {LocalisationSwitcher, UpgradeToProModal},
    setup() {
        const { xs } = useDisplay()
        const appStateStore = useAppStateStore()
        const router = useRouter()
        const route = useRoute()
        const accountId = ref(route.params.account_id?? null)

        const { currentAccount } = storeToRefs(appStateStore)

        router.afterEach((to, from) => {
            accountId.value = to.params.account_id
        })

        const handleLogout = async () => {
            const responseData = await api.post('/api/auth/fb/logout')
            if (responseData.status === 'success') {
                await appStateStore.reloadState()
                router.push({
                    name: 'login'
                })
            }
        }

        return { 
            appStateStore, 
            handleLogout, 
            xs,
            mdiAccountBoxOutline,
            mdiLogout,
            currentAccount,
            accountId,
            mdiHelpCircleOutline
        }
    }
}
</script>