<template>
    <v-dialog activator="parent" max-width="512" persistent>
        <template v-slot:default="{ isActive }">
            <v-card
                rounded="xl"
                flat
            >
                <p class="mt-2 mr-2 text-right">
                    <v-btn size="x-small" variant="tonal" :icon="mdiClose" @click="isActive.value = false"></v-btn>
                </p>
                <div class="pb-5 pt-1 px-8">
                    <v-row>
                        <v-col cols="12" width="100%">
                            <p class="text-center exie-text-h5 mb-4">{{$t('accountSettings.billing.fromLightToProModal.title')}}</p>
                            <p class="exie-text-body-1 text-center text-pre-line mt-5">
                                {{$t('accountSettings.billing.fromLightToProModal.description')}}
                            </p>
                            <p class="mt-10">
                                <v-btn class="float-left" variant="text" @click="isActive.value = false">
                                    {{$t('accountSettings.billing.fromLightToProModal.btnCancel')}}
                                </v-btn>
                                <v-btn class="float-right" color="primary" :loading="loading" @click="handleChangePlan(isActive)">
                                    {{$t('accountSettings.billing.fromLightToProModal.btnUpgradeToPro')}}
                                </v-btn>
                            </p>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
import { ref } from 'vue'
import { mdiClose} from '@mdi/js'
import api from '@/utils/api'
import { useRoute } from 'vue-router'

export default {
    emits: ['plan-changed'],
    setup(props, ctx) {
        const route = useRoute()
        const loading = ref(false)

        const handleChangePlan = async (isActive) => {
            loading.value = true
            const response = await api.post(`/api/billing/${route.params.account_id}/upgrade-to-pro`)
            loading.value = false
            if (response.error) {
                console.log('something went wrong')
                return
            }
            ctx.emit('plan-changed')
            isActive.value = false
        }

        return {
            mdiClose,
            handleChangePlan,
            loading
        }
    }
}
</script>