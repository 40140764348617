<template>
    <v-progress-linear v-if="!loaded" color="primary" indeterminate></v-progress-linear>
    <v-sheet v-else>
        {{ error }}
        <div v-if="availableAccounts" class="w-100">
            <div v-if="availableAccounts.length > 0" class="d-flex flex-column">
                <p class="exie-text-h5 my-5">
                    Select an account to connect your Shopify store 
                    <v-chip variant="flat" color="grey" class="mx-1">{{ route.query.shop }}</v-chip>
                </p>
                <v-sheet v-for="(account, index) in availableAccounts" :key="index"
                    class="border border-thin rounded-lg my-3"
                >
                    <v-row class="ma-5">
                        <v-col cols="12" md="2" class="pa-0 d-flex align-center">
                            <v-badge :content="getProStatusLabel(account.pro_status)" :color="getProStatusLabelColor(account.pro_status)">
                                <v-avatar color="grey-darken-3" :image="account.picture" size="45" />
                            </v-badge>
                        </v-col>
                        <v-col cols="6" md="3" class="pa-0">
                            <p class="exie-text-body-1 mt-1">{{ account.name }}</p>
                            <p class="exie-text-caption text-medium-emphasis">{{ account.instagram_username }}</p>
                        </v-col>
                        <v-col cols="6" md="7" class="pa-0 d-flex align-center justify-end">
                            <v-btn color="primary" varian="plain" flat @click="handleConnectToAccount(account.id)">Connect</v-btn>
                        </v-col>
                    </v-row>
                </v-sheet>
            </div>
            <p class="my-5 text-center">
                <v-btn color="primary" varian="plain" @click="handleCreateNewAccount" flat>Create New Account</v-btn>
            </p>
        </div>
    </v-sheet>
</template>

<script>
import api from '@/utils/api'
import {ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {getProStatusLabel, getProStatusLabelColor} from '@/utils/AccountUtils'
export default {
    setup() {
        const route = useRoute()
        const router = useRouter()
        const loaded = ref(false)
        const error = ref(null)
        const availableAccounts = ref(null)
        const init = async () => {
            const response = await api.get('/api/integrations/shopify/init?' + new URLSearchParams(route.query))
            if(response.error) {
                error.value = response.error
                return
            }

            if(response.redirect_to) {
                window.location.href = response.redirect_to
                return
            }

            if(response.available_accounts) {
                availableAccounts.value = response.available_accounts
            }

            loaded.value = true
        }
        
        const handleConnectToAccount = async (accountId) => {
            const response = await api.post(`/api/integrations/shopify/${accountId}/login`, {
                shopUrl: route.query.shop
            })

            if(response.error) {
                error.value = response.error
                return
            }

            if(response.redirect) {
                window.location.href = response.redirect
            }
        }

        const handleCreateNewAccount = async () => {
            router.push({
                name: 'account-create',
                query: {
                    shopify: route.query.shop
                }
            })
        }

        init()

        return {error, loaded, availableAccounts, getProStatusLabel, getProStatusLabelColor, handleConnectToAccount, handleCreateNewAccount, route}
    }
}
</script>