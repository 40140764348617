<template>
</template>

<script>
import { useAppStateStore } from '@/stores/AppStateStore';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const appStateStore = useAppStateStore()
    const router = useRouter()
    if (appStateStore.data.currentAccount) {
      router.push({
        name: 'dashboard',
        params: {
          account_id: appStateStore.data.currentAccount.id
        }
      })
    } else {
      router.push({
        name: 'accounts'
      })
    }
  }
}
</script>
