<template>
</template>

<script>
import {useMixPanelStore} from '@/stores/MixPanelStore'


export default {
    setup() {
        const mixPanelStore = useMixPanelStore()
        mixPanelStore.init()
    }
}
</script>