import { defineStore } from "pinia"
import api from "@/utils/api"

const TYPE_TEXT = 1
const TYPE_URL = 2
const TYPE_QNA = 3

export const useDocumentsStore = defineStore('documentsStore', {
    state: () => ({
        documents: [],
        accountId: null
    }),
    getters: {
        documentsCount: (state) => {
            return state.documents.length
        },
        documentsTextCount: (state) => {
            return state.documentsText.length
        },
        documentsQnaCount: (state) => {
            return state.documentsQna.length
        },
        documentsText: (state) => {
            return state.documents.filter((document) => document.type === TYPE_TEXT)
        },
        documentsUrls: (state) => {
            return state.documents.filter((document) => document.type === TYPE_URL)
        },
        documentsQna: (state) => {
            return state.documents.filter((document) => document.type === TYPE_QNA)
        }
    },
    actions: {
        async loadDocuments(accountId) {
            if (this.accountId != accountId || this.documents.length === 0) {
                this.accountId = accountId
                this.reloadDocuments(accountId)
            }
        },

        async reloadDocuments(accountId) {
            const responseData = await api.get(`/api/documents/${accountId}`)

            this.documents = responseData
        },

        deleteDocumentFromStore(documentId) {
            this.documents = this.documents.filter((document) => document.id != documentId)
        },
        addDocumentToStore(document) {
            this.documents.push(document)
        },
        updateDocument(document) {
            this.documents = this.documents.map((doc) => {
                if(doc.id == document.id) {
                    return document
                }

                return doc
            })
        }
    }
})