<template>
    <v-sheet width="100%">
        <GoogleSheets />
        <v-divider class="my-3" />
        <Shopify v-if="accountDataStore.hasFeatureFlag('feature_shopify')" />
    </v-sheet>
</template>

<script>
import GoogleSheets from './integrations/GoogleSheets.vue'
import Shopify from './integrations/Shopify.vue'
import {useAccountDataStore} from '@/stores/AccountDataStore'
import {useRoute} from 'vue-router'
export default {
    components: { GoogleSheets, Shopify },
    setup() {
        const route = useRoute()
        const accountDataStore = useAccountDataStore()
        accountDataStore.init(route.params.account_id)

        return {accountDataStore}
    }
}
</script>