<template>
    <v-card flat color="grey-lighten-4" rounded="xl" height="100%" min-height="250px" class="d-flex flex-column">
        <p class="mt-3 mx-6 exie-text-subtitle-1 font-weight-bold align-self-start">
            {{$t('dashboard.widget.contacts.title2')}}
        </p>
        <v-card-text class="d-flex flex-column justify-center align-center">
            <span class="exie-text-h3">{{ contactsCount }}</span>
        </v-card-text>
        <p class="mt-3"></p>
    </v-card>
</template>

<script>
export default {
    props: ['contactsCount']
}
</script>

<style scoped>
.mh-200 {
    min-height: 200px;
}
</style>