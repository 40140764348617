<template>
    <v-sheet class="w-100 h-100">
        <p class="exie-text-subtitle-1 font-weight-bold mb-2">{{$t('accountSettings.team.title')}}</p>  
        <v-row>
            <v-col cols="12" sm="5">
                <p class="exie-text-body-1 text-pre-line"> {{$t('accountSettings.team.description')}} </p>
            </v-col>
            <v-col cols="12" sm="7" class="text-right">
                <div v-if="isCurrentAccountOwner">
                    <InviteMemberModal />
                </div>
            </v-col>
        </v-row>
        <v-divider class="mt-8" />
        <v-list>
            <div v-for="(member, i) in members" :key="i">
                <v-list-item :key="i" class="my-1" >
                    <template v-slot:prepend>
                        <v-avatar color="grey-lighten-4" size="48" :image="member.user.picture"></v-avatar>
                    </template>
                    <v-list-item-title>{{ member.user.name }}</v-list-item-title>
                    <v-list-item-subtitle v-if="member.is_owner">{{ $t('accountSettings.team.ownerTitle') }}</v-list-item-subtitle>
                    <template v-slot:append>
                        <v-btn @click="deleteMember(member.user_id)" :disabled="member.is_owner || !isCurrentAccountOwner" variant="text" size="small">{{ $t('accountSettings.team.btnDelete') }}</v-btn>
                    </template>
                </v-list-item>
                <v-divider class="my-3" />
            </div>
            
        </v-list>
    </v-sheet>
</template>

<script>
import { ref } from 'vue'
import api from '@/utils/api'
import { useRoute } from 'vue-router'
import InviteMemberModal from '@/components/accountSettings/team/InviteMemberModal.vue'
import { useAccountInvitationsStore } from '@/stores/AccountInvitationsStore'
import { storeToRefs } from 'pinia'
import { useAppStateStore } from '@/stores/AppStateStore'
import {useAccountMembersStore} from '@/stores/AccountMembersStore'


export default {
    components: {InviteMemberModal},
    setup() {
        const route = useRoute()
        const appStateStore = useAppStateStore()
        const { isCurrentAccountOwner } = storeToRefs(appStateStore)
        const accountId = route.params.account_id

        const accountInvitationsStore = useAccountInvitationsStore()
        accountInvitationsStore.init(accountId)
        const { invitations } = storeToRefs(accountInvitationsStore)

        const accountMembersStore = useAccountMembersStore()
        accountMembersStore.init(accountId)
        const {members} = storeToRefs(accountMembersStore)

        const deleteMember = async (userId) => {
            await api.delete(`/api/team/${accountId}/member/${userId}`)
        }

        const deleteInvitation = async (invitationId) => {
            
            await api.delete(`/api/team/${accountId}/invitation/${invitationId}`)
        }

        return {members, invitations, accountId, isCurrentAccountOwner, deleteMember, deleteInvitation}
    }
}

</script>