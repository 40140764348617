<template>
    <v-container>
        <v-breadcrumbs class="text-medium-emphasis">
            <v-breadcrumbs-item :to="{name: 'accounts'}"> 
                {{ $t('accounts.title') }}
            </v-breadcrumbs-item>
            <v-breadcrumbs-divider />
            <v-breadcrumbs-item title="Create New Account" :to="{name: 'account-create'}">
                {{ $t('accountCreate.title') }}
            </v-breadcrumbs-item>
        </v-breadcrumbs>

        <v-row>
            <v-col cols="12">
                <div v-if="message" class="w-100 d-flex justify-center pa-5 exie-text-body-1">
                    {{ message }}
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import api from '@/utils/api'
import {useRoute, useRouter} from 'vue-router'
import {ref} from 'vue'
import {useI18n} from 'vue-i18n'
import {useAppStateStore} from '@/stores/AppStateStore'
export default {
    setup() {
        const {t} = useI18n()
        const message = ref(null)
        const route = useRoute()
        const router = useRouter()

        const appStateStore = useAppStateStore()

        const init = async () => {
            message.value = null
            const response = await api.get(`/api/account/check-access/${route.params.ig_id}`)

            if(response.error) {
                message.value = t('accountCreate.errorAccountNotFound')
                return
            }

            if(response.has_access == false) {
                message.value = t('accountCreate.errorNoAccess')
                return
            }

            if(response.has_access == true) {

                router.push({
                    name: 'dashboard',
                    params: {
                        account_id: response.account_id
                    }
                })
            }
        }

        init()

        return {message}
    }
}
</script>