<template>
    <v-container>
        <h4 class="exie-text-h4 mb-3">{{$t('history.title')}}</h4>
        <v-row class="my-5 exie-text-body-1">
            <v-col cols="12" sm="6" md="5">
                {{$t('history.description')}}
            </v-col>
        </v-row>
        <v-row class="mb-8">
            <v-col cols="12" sm="5" md="4">
                <v-select
                    v-model="filter"
                    single-line
                    :items="[
                        {title: $t('history.filter.allMessagesTitle'), value: 'all'}, 
                        {title: $t('history.filter.withContactUsButtobTitle'), value: 'contact'}, 
                        {title: $t('history.filter.countactUsButtonClickedTitle'), value: 'contact_pushed'}
                    ]"
                    hide-details
                    item-title="title"
                    item-value="value"
                    max-width="250px"
                    rounded="pill"
                    variant="outlined"
                    @update:modelValue="handleFilterChanged"
                ></v-select>
            </v-col>
        </v-row>
        
        <v-sheet v-if="smAndUp">
            <v-row class="mb-2 text-uppercase text-medium-emphasis">
                <v-col cols="2">
                    {{$t('history.contactTitle')}}
                </v-col>
                <v-col cols="4">
                    {{ $t('history.inMessageTitle') }}
                </v-col>
                <v-col cols="4">
                    {{ $t('history.outMessageTitle') }}
                </v-col>
                <v-col cols="2">
                    {{ $t('history.timeTitle') }}
                </v-col>
            </v-row>
            <v-divider />
            <v-sheet class="mt-2">
                <v-infinite-scroll :key="infiniteScrollKey" width="100%" :items="messages" :onLoad="loadMessages">
                    <template v-for="(message, index) in messages" :key="index">
                        <v-row class="text-jusify w-100">
                            <v-col cols="2">
                                <div class="exie-text-subtitle-2" v-if="message.contact_id == 0">{{ $t('history.playgroundContact') }}</div>
                                <div class="exie-text-subtitle-2" v-else-if="message.contact">
                                    <p>@{{ message.contact.ig_username }}</p>
                                </div>
                                <div class="exie-text-subtitle-2" v-else>{{ message.contact_id }}</div>
                                <div class="mt-2">
                                    <v-chip 
                                        v-if="message?.contact?.is_automation_active == false"
                                        :prepend-icon="mdiPause" 
                                        closable
                                        variant="flat"
                                        color="orange-lighten-4"
                                        size="small"
                                        @click:close="handleResetPauseAutomation(message.contact_id)"
                                    >
                                        <v-tooltip
                                            activator="parent"
                                            location="top"
                                        >{{ $t('history.automationPausedTooltip') }}</v-tooltip>
                                        {{ $t('history.automationPaused') }}
                                    </v-chip>
                                </div>
                            </v-col>
                            <v-col cols="4" class="py3">
                                <div class="in"> 
                                    {{ message.in_message }}
                                </div>
                            </v-col>
                            <v-col cols="4" class="py-3">
                                <div class="out">
                                    {{ message.out_message }}
                                </div>
                                <div v-if="message.meta?.buttons" class="mt-1">
                                    <div v-for="(button, index) in message.meta.buttons" :key="index" class="out_button">
                                        {{ button.title }}
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="2" class="d-flex justify-end text-right exie-text-subtitle-2">
                                <div :style="{maxWidth: '110px'}">
                                    {{ formatToShortDate(message.ts_created) }}
                                </div>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:empty>
                        <p v-if="!messages.length">{{ $t('history.nothingHere') }}</p>
                        <p v-else>{{ $t('history.noMoreItems') }}</p>
                    </template>
                </v-infinite-scroll>
            </v-sheet>
        </v-sheet>
        <v-sheet v-else class="bg-grey-darken-4 rounded-xl mt-3 pa-16 d-flex pa-10 flex-column justify-center align-center">
            <p><v-icon :icon="mdiAutorenew"></v-icon></p>
            <p class="mt-3">{{ $t('history.pleaseRotate') }}</p>
        </v-sheet>
    </v-container>
    <v-snackbar location="top right" color="success" v-model="isPauseAutomationReseted" :timeout="4000">
        {{$t('history.notify.pauseAutomationReseted')}}
    </v-snackbar>
</template>

<script>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import api from '@/utils/api'
import { useDisplay } from 'vuetify'
import { formatToShortDate } from '@/utils/dataFormatter'
import {mdiPause, mdiCloseCircle, mdiAutorenew} from '@mdi/js'

export default {
    setup() {
        const route = useRoute()
        const accountId = route.params.account_id
        const lastMessageId = null
        const loading = ref(false)
        const listCompleted = ref(false)
        const isPauseAutomationReseted = ref(false)
        const filter = ref('all')
        const infiniteScrollKey = ref(0)

        const {smAndUp} = useDisplay()

        const messages = ref([])

        const handleFilterChanged = async () => {
            messages.value = []
            infiniteScrollKey.value += 1
        }

        const handleResetPauseAutomation = async (contactId) => {
            
            const response = await api.post(`/api/contact/${accountId}/reset-automation-pause`, {
                contact_id: contactId
            })

            if(!response.error) {
                isPauseAutomationReseted.value = true
                messages.value.forEach((mes, index) => {
                if(messages.value[index].contact_id == contactId) {
                        messages.value[index].contact.is_automation_active = true
                    }
                })

                setTimeout(() => {
                    isPauseAutomationReseted.value = false
                }, 3000)
            }
        }

        const loadMessages = async ({ done }) => {
            loading.value = true
            const params = {
                filter: filter.value
            }
            if (messages.value.length) {
                params['cursor'] = messages.value[messages.value.length - 1].id
            }
            
            const responseData  = await api.get(`/api/history/${accountId}/messages?` + new URLSearchParams(params))

            
            if (!responseData.length) {
                done('empty')
                return
            }
            
            messages.value.push(...responseData)
            done('ok')
        }

        return { 
            messages, 
            loadMessages, 
            loading, 
            listCompleted, 
            handleResetPauseAutomation, 
            isPauseAutomationReseted,
            smAndUp,
            filter,
            infiniteScrollKey,
            handleFilterChanged,
            formatToShortDate,
            mdiPause,
            mdiCloseCircle,
            mdiAutorenew
        }
    }
}
</script>

<style scoped>
.in {
    border-radius: 10px;
    background: #424242;
    color: #ffffff;
    max-width: 30dvw;
    min-width: 10dvw;
    padding: 10px 20px;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
}
.out {
    border-radius: 10px;
    background: #eeeeee;
    color: #000000;
    max-width: 30dvw;
    min-width: 10dvw;
    padding: 10px 20px;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
}
.out_button {
    background: #eeeeee;
    color: #000000;
    max-width: 30dvw;
    min-width: 10dvw;
    padding: 5px 10px;
    text-align: center;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
}
</style>