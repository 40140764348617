<template>
    <v-container>
        <h4 class="exie-text-h4 mb-3">{{$t('accounts.title')}}</h4>
        <v-sheet class="mt-10 w-100">
            <v-row>
                <v-col cols="12" md="5">
                    <span class="exie-text-body-1">
                        {{$t('accounts.description')}}
                    </span>
                </v-col>
                <v-col cols="12" md="7">
                    <div class="h-100 d-flex align-end justify-end">
                        <v-btn flat @click="handleCreateAccount" color="primary">{{$t('accounts.btnCreate')}}</v-btn>
                    </div>
                </v-col>
            </v-row>

            <div v-if="accounts.length > 0" class="w-100 mt-5">
                <v-sheet v-for="(account, index) in accounts" :key="index"
                    class="border border-thin rounded-lg my-3"
                >
                    <v-row class="ma-5">
                        <v-col cols="12" md="2" class="pa-0 d-flex align-center">
                            <v-badge :content="getProStatusLabel(account.pro_status)" :color="getProStatusLabelColor(account.pro_status)">
                                <v-avatar color="grey-darken-3" :image="account.picture" size="45" />
                            </v-badge>
                        </v-col>
                        <v-col cols="6" md="3" class="pa-0">
                            <p class="exie-text-body-1 mt-1">{{ account.name }}</p>
                            <p class="exie-text-caption text-medium-emphasis">{{ account.instagram_username }}</p>
                        </v-col>
                        <v-col cols="6" md="7" class="pa-0 d-flex align-center justify-end">
                            <v-btn v-if="currentAccount.id == account.id" disabled varian="plain" flat>{{$t('accounts.btnSelected')}}</v-btn>
                            <v-btn v-else varian="plain" flat color="grey-lighten-4" @click="changeCurrentAccount(account.id)">{{$t('accounts.btnSelect')}}</v-btn>
                        </v-col>
                    </v-row>
                </v-sheet>
            </div>
            <div v-else class="mt-5 w-100">
                <v-sheet class="rounded-lg bg-grey-lighten-5 py-15 px-5 d-flex flex-column align-center text-center">
                    <div class="exie-text-h4">👀</div>
                    <div class="exie-text-body-2 mt-3 text-medium-emphasis" :style="{maxWidth: '250px'}">{{$t('accounts.noAccountsDescription')}}</div>
                </v-sheet>
            </div>
            
        </v-sheet>
    </v-container>
    
</template>

<script>
import { useRouter } from 'vue-router'
import { useAppStateStore } from '../stores/AppStateStore.js'
import { getProStatusLabel, getProStatusLabelColor } from '@/utils/AccountUtils'
import { useMixPanelStore } from '@/stores/MixPanelStore'
import api from '@/utils/api'
import { storeToRefs } from 'pinia'
export default {
    components: {},
    setup() {
        const router = useRouter()
        const mixPanelStore = useMixPanelStore()
        const appStateStore = useAppStateStore()
        const {currentAccount, accounts} = storeToRefs(appStateStore)
        const handleCreateAccount = () => {
            mixPanelStore.track('click_create_account_button')
            router.push({name: 'account-create'})
        }

        const changeCurrentAccount = async (accountId) => {
            router.push({
                name: 'dashboard',
                params: {
                    account_id: accountId
                }
            })
        }

        return { handleCreateAccount, currentAccount, accounts, changeCurrentAccount, getProStatusLabel, getProStatusLabelColor  }
    }
}
</script>

<style scoped>

</style>