import { defineStore } from "pinia"
import api from '@/utils/api'

export const useAccountStatsStore = defineStore('accountStatsStore', {
    state: () => ({
        accountId: null,
        contactsCount: null,
        messagesAvailable: null,
        subscriptionMessagesAvailable: null,
        extraMessagesAvailable: null,
        messagesLimit: null,
        messagesSent: null
    }),
    actions: {
        async init(accountId) {
            if(!this.accountId || this.accountId != accountId) {
                await this.reload(accountId)
            }
        },
        async reload(accountId) {
            const result = await api.get(`/api/account/${accountId}/stats`)
            this.accountId = accountId
            this.contactsCount = result.contacts_count
            this.messagesSent = result.messages_sent
            this.messagesLimit = result.messages_limit
            this.messagesAvailable = result.messages_available
            this.extraMessagesAvailable = result.extra_messages_available
            this.subscriptionMessagesAvailable = result.subscription_messages_available
        },
        updateMessagesLimit(messagesLimit) {
            this.messagesLimit = messagesLimit
        },
        updateMessagesAvailable(messagesAvailable) {
            this.messagesAvailable = messagesAvailable
        },
        updateSubscriptionMessagesAvailable(subscriptionMessagesAvailable) {
            this.subscriptionMessagesAvailable = subscriptionMessagesAvailable
        },
        updateExtraMessagesAvailable(extraMessagesAvailable) {
            this.extraMessagesAvailable = extraMessagesAvailable
        }
    }
})